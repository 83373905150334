//shop{
.shop{
  &-blc{
    position: relative;
    @include mq{
      padding-top: 30px;
    }
    .shop_list &{
      @include mq(pc){
        padding-top: 60px;
      }
    }

    & + &{
      margin-top: 60px;
      @include mq(pc){
        border-top: 1px solid $color-bdr02;
      }
      @include mq{
        margin-top: 40px;
      }

    }
    .shop_list &{
      @include mq(pc){
        display: grid;
        grid-template-columns: 52% auto;
        grid-template-rows: 40px auto auto auto 80px;
        gap: 15px 50px;
      }
    }
    &._new{
      &:after{
        content: "NEW";
        position: absolute;
        left: calc((90 / 390) * 100vw);
        top: 0;
        color: $white;
        background-color: $red;
        font-weight: 600;
        padding: 3px 10px;
        text-align: center;
        display: block;
        @include mq(pc){
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          padding: 3px 10px;
          left: 90px;
          @include flx(row,center,center,);
          .shop_list &{
            height: 40px;
            padding: 0 10px;
          }
        }
        @include mq{
          @include sfs(12);
        }
      }
    }
    .btn._sbtn{
      @include mq(pc){
        grid-column: 1 / 2;
        grid-row: 5 / 6;
      }
    }
  }
  &-cat{
    &:before{
      color: $white;
      font-weight: 600;
      padding: 3px 0;
      text-align: center;
      display: block;
      @include mq(pc){
        width: 80px;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        @include flx(row,center,center,);
      }
      @include mq{
        width: calc((90 / 390) * 100vw) ;
        position: absolute;
        top: 0;
        left: calc((-16 / 390) * 100vw);
        @include sfs(12);
      }
    }
    &._01{
      &:before{
        content: "加盟店";
        background-color: $shopcat-color01;
      }
    }
    &._02{
      &:before{
        content: "協賛店";
        background-color: $shopcat-color02;
      }
    }
  }
  &-ttl{
    @include flx(column,center,center,);
    @include mq(pc){
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      font-size: 2.6rem;
    }
    @include mq{
      @include sfs(20);

    }
    .shop_list &{
      @include mq(pc){
        align-items:flex-start;
      }
    }

    >span{
      font-size: 1.4rem;
      @include mq{
        @include sfs(12);
      }
    }
  }
  &-slider{
    @include mq(pc){
      grid-column: 1 / 2;
      grid-row: 1 / 5;
    }
    figure{
      overflow: hidden;
      aspect-ratio: 376 / 242;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .shop_list &{
      width: 100%;
      @include mq{
        width: 100vw;
        margin: calc((15 / 390) * 100vw) calc(50% - 50vw) 0;
      }
      &._nomore{
        @include mq(pc){
          width: 83%;
        }
        @include mq{
          width: 100%;
          margin: calc((15 / 390) * 100vw) 0 0;
          padding: 0 calc((2 / 390)* 100vw);
        }
      }
      figure{
        border-radius: 10px;
        @include mq{
          border-radius: calc((15 / 390) * 100vw);
        }
      }
    }
    .shop_dtl &{
      padding-bottom: 50px;
      margin-top: 20px;
      margin-bottom: 20px;
      @include mq{
        padding-bottom: calc((40 / 390) * 100vw);
        margin-top: calc((15 / 390) * 100vw);
      }
      &._nomore{
          padding-bottom: 0;
          @include mq(pc){
            width: 67%;
          }
      }
    }
  }
  &-info{
    @include flx(column,flex-start,flex-start,10px);
    @include mq{
      margin-top: calc((20 / 390) * 100vw);
    }
    .shop_dtl &{
      @include mq(pc){
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px 50px;
        margin:40px 0;
      }
    }
    >div{
      @include flx(row,flex-start,flex-start,15px);
      .shop_dtl &{
        border-bottom: 1px solid $color-bdr02;
        width: 100%;
        padding-bottom: 15px;
        @include mq{
          padding-bottom: calc((10 / 390) * 100vw);
        }
      }
      dt{
        @include flx(row,flex-start,center,);
        >span{display: none;}
      }
      dd{
      }
      &._add{
        @include mq(pc){
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }
        @include mq{
          border-bottom: none;
        }
        .shop_dtl &{
          @include mq(pc){
          }
          @include mq{
            border-top: 1px solid $color-bdr02;
            padding-top: calc((10 / 390) * 100vw);
          }
        }
        dt{
          &:before{
            @include icon('icon_add.svg', 20px, 20px)
          }
        }
      }
      &._tel{
        @include lh(12,18)
        @include mq(pc){
          grid-column: 1 / 2;
          grid-row: 2 / 3;
        }
        dt{
          &:before{
            @include icon('icon_tel.svg', 20px, 20px)
          }
        }
      }
      &._time{
        @include mq(pc){
          grid-column: 1 / 2;
          grid-row: 3 / 4
        }
        dt{
          &:before{
            @include icon('icon_time.svg', 20px, 20px)
          }
        }
      }
      &._url{
        @include mq(pc){
          grid-column: 1 / 2;
          grid-row: 4 / 5;
        }
        dt{
          &:before{
            @include icon('icon_url.svg', 20px, 20px)
          }
        }
      }
      &._shopcat{
        @include mq(pc){
          grid-column: 1 / 2;
          grid-row: 5 / 6;
        }
        dt{
          &:before{
            @include icon('icon_kamei.svg', 20px, 20px)
          }
        }
      }
      &._map{
        width: 100%;
        height: 100%;
        border-bottom: none;
        @include mq(pc){
          grid-column: 2 / 3;
          grid-row: 1 / 7;
          padding-bottom: 0;
        }
        @include mq{
          aspect-ratio: 376 / 232;
        }
        iframe{
          width: 100%;
          height: 100%;
        }
      }
      &._exlink{
        border-bottom: none;
        @include mq(pc){
          grid-column: 1 / 2;
          grid-row: 6 / 7;
        }
        a{
          border: 1px solid $color-bdr01;
          background-color: $white;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          text-decoration: none;
          width: 70%;
          img{
            width: 75%;
          }
        }
      }
    }
  }
  &-badge{
    @include flx(row,center,stretch,);
    flex-wrap: wrap;
    @include mq{
      margin-top: calc((20 / 390) * 100vw);
    }
    .shop_dtl &{
      background:$color-bg01;
      padding: 20px;
      border-radius: 10px;
    }

    li{
      @include flx(column,space-between,center,5px);
      width: 25%;
      position: relative;
      @include lh(12,14);
      @include mq{
        @include sfs(12);
        text-align: center;
        gap: 10px;
      }
      &:not(:last-child){
        &:after{
          content:"";
          display: block;
          background:$gray01;
          width: 1px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      span{
        @include mq(pc){
          text-align:center;
          font-size: 1.4rem;
        }
        &.stts{
          font-weight: 900;
          font-size: 3.0rem;
          @include mq{
            @include sfs(20);
          }

          &._on{
            color: #98EA91;
          }
          &._off{
            color: $gray01;
          }
        }
      }

    }
  }
  &-badge-txt{
    @include mq(pc){
      margin:50px 0;
    }
    @include mq{
      margin-top: calc((20 / 390) * 100vw);
    }
    >div{
      @include flx(row,flex-start,flex-start,);
      flex-wrap: wrap;
      margin-top: 15px;
      @include mq{
        @include sfs(13);
        margin-top: calc((8 / 390) * 100vw);
      }
      dt{
        width: 25%;
        @include mq{
          width: 42%;
        }
      }
      dd{
        width: 75%;
        @include mq{
          width: 58%;
        }
      }
    }
  }
  &-special{
    background: #D1EEF4;
    margin-top: 50px;
    padding: 30px;
    @include mq{
      width: 100vw;
      margin:calc((20 / 390) * 100vw) calc(50% - 50vw);
      padding: calc((15 / 390) * 100vw);
    }
    dt{
      font-weight: 600;
      margin-bottom: 10px;

      @include mq{
        @include sfs(13);
        margin-bottom: calc((5 / 390) * 100vw);
      }
    }
    dd{
      @include mq{
        @include sfs(12);
      }
      .tokubox{
        & + .tokubox{
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid $white;
        }
        >p{
          font-weight: 700;
          font-size:2.0rem;
          margin-bottom: 10px;
          @include mq{
            @include sfs(15);
          }
        }
        >span._cond{
          margin-top: 10px;
          display: inline-block;
          background: $white;
          border-radius:5px;
          padding: 5px 10px;
        }
      }
    }

  }
  .box-desc{
    @include mq{
      margin-top: calc((15 / 390) * 100vw);
    }
  }
  .btn{
    @include mq{
      margin-top: calc((20 / 390) * 100vw);
    }
  }
  &_list{
    @include mq{
      margin-top: calc((20 / 390) * 100vw);
    }
    .box-desc{
      @include mq{
        @include line-clamp(2);
        text-align: center;
      }
    }
  }
  &-searchhead{
    ._cd{
      color: $gray01;
      font-weight: 700;
      font-size: 1.8rem;
      @include flx(row,flex-start,center,10px);
      flex-wrap: wrap;
      border: 1px solid $color-bdr02;
      background-color: $color-bg01;
      border-radius: 10px;
      @include mq(pc){
        padding: 20px;
        margin-bottom: 20px;
      }
      @include mq{
        @include sfs(14);
        padding: calc((10 / 390) * 100vw);
        margin-bottom: calc((20 / 390) * 100vw);
      }
      span{
        border: 1px solid $color-bdr01;
        border-radius:10px ;
        padding: 5px 10px;
        background-color: $white;
        display: inline-block;
      }
    }
    >div{
      padding-top: 5px;
      @include flx(row,space-between,center,10px);
    }
    ._num{
      span{
        font-size: 2.0rem;
        font-weight: 700;
        @include mq{
          @include sfs(18);
        }
      }
    }
    ._ssbtn{
      margin-top: 0;
      // a{
      //   padding: 3px 10px;

      // }
      &._search{
        a{
          &:before{
            @include icon('icon_search.svg', 24px, 24px);
          }
        }
        &:before{
          content:none;
        }
      }
    }
  }
  &-sort{
    @include flx(row,flex-start,center,20px);
    @include mq{
      gap:10px;
    }
    .btn{
      @include mq{
        margin: 0;
      }

    }

  }
  &-searchbtn{
    a{
      border-radius: 50%;
      position: fixed;
      right: 20px;
      bottom: 20px;
      background-color: $gray01;
      @include flx(row,center,center,);
      width: 60px;
      height: 60px;
      z-index: 100;
      border: 2px solid $white;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    }
  }
  &-prevbtn{
    a{
      border-radius: 50%;
      position: fixed;
      bottom: 20px;
      left: 15px;
      background-color: $white;
      @include flx(row,center,center,);
      width: 40px;
      height: 40px;
      z-index: 100;
      border: 2px solid $white;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      text-indent: -9999px;
      &:after {
        @include svgarrow(15px, 15px);
        transform: rotate(180deg);
      }
    }
  }
}