$breakpoints: (
  "pc": "screen and (min-width: 769px)",
  "lpc": "screen and (min-width: 1161px)",
  "mpc": "screen and (max-width: 1160px) and (min-width: 769px)",
  "spc": "screen and (max-width: 1020px) and (min-width: 769px)",
  "lsp": "screen and (max-width: 768px)",
  "rsp": "screen and (max-width: 528px)",
  "msp": "screen and (max-width: 414px)",
  "ssp": "screen and (max-width: 320px)"
) !default;

@mixin mq($breakpoint: lsp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$text-color: #1D1D1D;

$main-color: #93D0DB;
$main-color_b: #6FC6D6;
$main-color_b02: #119ABB;
$key-color01:#75BA7E;
$key-color01_b:#75BB7D;
$key-color02:#E7BC8D;
$key-color02_b:#F0993B;
$key-color03:#F7DCD4;
$key-color03_b:#EDA991;

$cate-color01:#FFF09B;
$cate-color02:#CBE7F2;
$cate-color03:#C7DCF8;
$cate-color04:#FFC7C7;
$shopcat-color01:#E8AC59;
$shopcat-color02:#59A1E8;

$color-bdr01: #ACACAC;
$color-bdr02: #e0e0e0;
$color-bg01:#F5F5F5;
$color-bg02:#ebf1f3;
$color-bg03:#fbefef;
$color-bg04: #FFF6EB;

$color-disabled-text: #dfdfdf;
$color-disabled-bg: #cacaca;

$color-error-text:#E54D39;
$color-error-bg:#fbefef;

$gray01: #9B9B9B;
$gray02: #E5E5E5;
$gray03: #E8E8E8;
$white: #fff;
$black: #000;
$red :#E54D39;
$color-yt: #cd201f;
$color-fb: #3b5998;
$color-tw: #55acee;
$color-ln: #00b900;

$main-width: 1160px;
$pc-header-height: 120px;
$sp-header-height: calc((50 / 390) * 100vw);

$font-serif:'Noto Serif JP', serif;
$font-robot: 'Roboto', sans-serif;
$font-sans_serif: 'Noto Sans JP', sans-serif;

@mixin f_sans_serif($weight) {
	font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
	font-weight: $weight;
  font-style: normal;
}

@mixin f_hiragino( $weight ) {
	font-family: "hiragino-kaku-gothic-pron", sans-serif;
	font-weight: $weight;
  font-style: normal;
}
@mixin f_noto( $weight ) {
	font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
	font-weight: $weight;
  font-style: normal;
}

@mixin f_kiwi( $weight ) {
	font-family: "Kiwi Maru", serif;
	font-weight:  $weight;
  font-style: normal;
}

@mixin inner {
  max-width: $main-width + 40;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  @include mq {
    padding: 0 calc((16 / 390) * 100vw);
  }
}
@mixin inner02 {
  max-width: $main-width + 40;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  @include mq {
    padding-left:  calc((20 / 390) * 100vw);
    padding-right:  calc((20 / 390) * 100vw);
  }
}

@mixin lh($fontSize, $height) {
  line-height: $height / $fontSize;
}
@mixin ls($tracking) {
  letter-spacing: #{$tracking / 1000}em;
}
@mixin sfs($spfontSize) {
  font-size: #{($spfontSize / 390) * 100}vw;
}

@mixin transition($prop: all, $dur: 0.5, $func: ease, $delay: 0) {
  transition: $prop #{$dur}s $func #{$delay}s;
}

@mixin link_color($color) {
  &,
  &:visited,
  &:hover {
    color: $color;
  }
}



@mixin arrow($width, $bold, $color, $deg) {
  border-style: solid;
  border-width: 0 #{$bold}px #{$bold}px 0;
  content: "";
  display: inline-block;
  border-color: $color;
  @if ($deg == "left") {
    transform: rotate(135deg);
  } @else if ($deg == "top") {
    transform: rotate(225deg);
  } @else if ($deg == "right") {
    transform: rotate(-45deg);
  } @else if ($deg == "bottom") {
    transform: rotate(45deg);
  }
  height: #{$width}px;
  width: #{$width}px;
}

@mixin svgarrow($width,$height,$baseColor: "93D0DB") {
	width: $width;
	height: $height;
  content:"";
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.157' height='13.687' viewBox='0 0 6.157 13.687'%3E%3Cpath d='M748.83,874.686a1,1,0,0,1-.814-1.58l3.745-5.263-3.745-5.264a1,1,0,1,1,1.63-1.159l4.157,5.843a1,1,0,0,1,0,1.159l-4.157,5.843A1,1,0,0,1,748.83,874.686Z' transform='translate(-747.831 -861)' fill='%23#{$baseColor}'/%3E%3C/svg%3E");
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  transition-duration: 0.5s;
}


$path_img: "../img/parts/";
@mixin icon($fileName, $width, $height) {
  content:"";
	background: url($path_img + $fileName) no-repeat 50% 50%;
	background-size: $width $height;
	width: $width;
	height: $height;
  display: block;
  transition-duration: 0.5s;
}

@mixin object-fit($option) {
  width: 100%;
  height: 100%;
  object-fit: $option;
}

@mixin line-clamp($count: 3) {
  @if type-of($count) != 'number' {
    @error 'line-clampの引数は必ず数値で指定してください';
  }

  @if $count == 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@mixin flx($direction: row, $justify: center, $align: center, $gap:0 ) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}