body{
  @include f_sans_serif(400);
  color: $text-color;
  word-break:break-all;
  @include lh(16,27);
  @include mq(pc){
    overflow-x: hidden;
  }
  &._open{
    overflow: hidden;
    &:before{
      @include mq(pc){
        content:"";
        background: rgba(0,0,0,0.3);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        z-index: 15;
      }
    }
  }
}
.inner {
  @include inner;
}


[v-cloak] {
  opacity: 0;
  display: none
}
a[href^="tel:"] {
  @include mq(pc){
    pointer-events: none;
    cursor: default;
  }
}
// #app{
//   overflow-x: hidden;

// }

.cmn-sec{
  @include inner02;
  + .cmn-sec{
    margin-top: 80px;
    @include mq{
      margin-top: calc((50 / 390) * 100vw);
    }
  }
  &._line{
    border-top: 2px solid $main-color;
    padding-top: 60px;
    @include mq{
      padding-top: calc((40 / 390) * 100vw);
    }
    &._orange{
      border-color: $key-color02;
    }
    &._green{
      border-color: $key-color01;
    }
    &._pink{
      border-color: $key-color03;
    }
  }
  &._bg{
    background-color:$color-bg01;
    @include mq(pc){
      padding: 50px;
    }
    @include mq{
      padding-top: calc((40 / 390) * 100vw);
      padding-bottom: calc((40 / 390) * 100vw);
    }
  }
}
.cmn-box{
  // & + &{
  //   margin-bottom: 60px;
  //   @include mq{
  //     margin-bottom: calc((30 / 390) * 100vw);
  //   }
  // }
  &:not(:last-child){
    margin-bottom: 60px;
    @include mq{
      margin-bottom: calc((40 / 390) * 100vw);
    }
  }
}
.cmn-item{
  &:not(:last-child){
    margin-bottom: 30px;
  }
}

.page {
  padding: calc(#{$pc-header-height} + 30px) 0 80px;
  
  &.neko,&.kamei{
    @include mq(pc){
      padding: calc(#{$pc-header-height}) 0 80px;
      margin-bottom: -60px;
    }
    @include mq{
      margin-bottom: -30px;
    }
  }
  &.tamaruntown{
    @include mq(pc){
      padding-bottom:0;
      margin-bottom: -60px;
    }
    @include mq{
      margin-bottom: -30px;
    }
  }
  @include mq{
    padding: calc(#{$sp-header-height} ) 0 0;
    @include sfs(15);
  }
  &.about, &.howtouse, &.howtoget, &.forshop{
    @include mq{
      padding-top: calc(#{$sp-header-height} + calc((30 / 390) * 100vw) );
    }
  }
  &-head{
    figure{
      text-align: center;
      margin-bottom: 15px;
      font-size: 1.8rem;
    }
    @include mq(pc){
      text-align: center;
      font-size: 1.8rem
    }
    @include mq{
      @include sfs(16);
    }
  }
  &-ttl{
    text-align: center;
    @include mq(pc){
      margin-bottom: 50px;
      font-size: 2.4rem;
    }
    @include mq{
      @include sfs(22);
      margin-bottom: calc((30 / 390) * 100vw);
    }
    &._sub{
      background-color: $main-color;
      color: $white;
      padding: 20px 0;
      @include mq{
        padding: calc((10 / 390) * 100vw) 0;
      }
    }
  }
  &-ttlimg{
    @include mq(pc){
      height:100px;
    }
    @include mq{
      height:calc((72 / 390) * 100vw) ;
    }
    .howtoget &{
      @include mq(pc){
        height:100px;
      }
      @include mq{
        height:calc((89 / 390) * 100vw)  ;
        margin-top: calc((-17 / 390) * 100vw) ;
      }
    }

    img{
      height: 100%;
      .about &{
        @include mq{
          height:calc((72 / 390) * 100vw)  ;
        }
      }
      .howtouse &{
        @include mq{
          height:calc((65 / 390) * 100vw)  ;
        }
      }
      .howtoget &{
        @include mq{
          height:calc((89 / 390) * 100vw)  ;
        }
      }
    }
  }
}

.cmn-form {
  @include mq(pc) {
    margin: 60px 0 80px;
  }
  @include mq {
    margin-top: calc((30 / 390) * 100vw) ;
  }
  > div {
    @include mq(pc) {
      display: flex;
      justify-content: space-between;
    }
    + div {
      margin-top: 40px;
      @include mq {
        margin-top: calc((30 / 390) * 100vw) ;
      }
    }
  }
  dt {
    font-weight: 700;
    @include mq(pc) {
      font-size: 1.8rem;
      width: 300px;
      padding: 10px 20px 0 0;
    }
    @include mq {
      @include sfs(15);
    }
  }
  &._conf{
    dt{
        padding:0;
    }
  }
  dd {
    @include mq(pc) {
      width: calc(100% - 300px);
    }
    @include mq {
      margin-top: calc((10 / 390) * 100vw);
    }
    &._pdg{
      @include mq(pc) {
        padding: 10px 0 0;
      }
    }
    .half_blc{
      display: flex;
      label{
        display: flex;
        gap:10px;
        align-items:center;
      }
    }
  }
  .req {
    color: $white;
    font-size: 1.3rem;
    background: $red;
    padding: 2px 6px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    @include mq(pc) {
      margin-left: 15px;
    }
    @include mq {
      @include sfs(12);
      margin-left: calc((10 / 390) * 100vw);
    }
  }
  
  .st{
    @include mq(pc) {
      margin-bottom:10px;
    }
    @include mq {
      margin-bottom:calc((10 / 390) * 100vw);
    }
  }
  .cap{
    display: inline-block;
    @include mq(pc) {
      font-size: 1.4rem;
      margin-top:10px;
    }
    @include mq {
      @include sfs(13);
      margin-top:calc((10 / 390) * 100vw);
    }
  }
  ._error{
    color: $color-error-text;
    @include mq {
      @include sfs(12);
      margin-top:calc((10 / 390) * 100vw);
    }
  }
  .op {
    color: $main-color;
    font-size: 1.3rem;
    @include ls(110);
    background: rgba(117, 98, 92, .16);
    padding: 1px 6px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    margin: -3px 8px 0 0;
    @include mq {
      font-size: 1.2rem;
      margin-right: 5px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    li {
      @include mq(pc) {
        margin-top: 10px;
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
      @include mq {
        &:nth-child(n+3) {
          margin-top: 10px;
        }
        &:nth-child(odd) {
          padding-right: 10px;
        }
        &:nth-child(even) {
          padding-left: 10px;
        }
      }
    }
  }
}
::placeholder {
  color: rgba(72, 43, 37, 0.3);
}

input:not([type="submit"]):not([type="button"]),
textarea, select {
  background: $white;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  border: 1px solid $color-bdr01;
  @include f_sans_serif(400);
  color: $text-color;
  &.error{
    border: 1px solid $color-error-text;
    background: $color-error-bg;
  }
  & + &{
    margin-top: 10px;
  }
}


input[type="submit"], input[type="button"] {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.check-wrap {
  display: flex;
  @include flx(row, flex-start,flex-start,10px)
  cursor: pointer;
  input[type="checkbox"] {
    display: none;
    &:checked{
      + span{
        &:after{
          opacity: 1;

        }
      }
    }
    + span {
      width: 30px;
      height: 30px;
      background: $white;
      border: 1px solid $color-bdr01;
      border-radius: 4px;
      margin: -2px 0 0 0;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      flex-shrink:0;
      &:after {
        content: '';
        display: block;
        width: 14px;
        height: 8px;
        border-left: 2px solid $main-color_b02;
        border-bottom: 2px solid $main-color_b02;
        transform: rotate(-45deg);
        position: absolute;
        top: 0;
        bottom: 4px;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: .5s;
      }
    }
  }
}

.check-wrap_btn{
  position: relative;
  label{
    input{
      display: none;
      &:checked{
        + span {
          color: $white;
          background: $main-color_b;
          transition: .5s;
        }
      }
    }
    span {
      background-color: $gray03;
      border-radius: 30px;
      padding: 10px 20px;
      display: flex;
    }
  }
}



.radio-wrap {
  display: inline-block;
  cursor: pointer;
  input[type="radio"] {
    display: none;
    + span {
      width: 20px;
      height: 20px;
      background: #FEFBF6;
      border: 1px solid #CFCAC5;
      border-radius: 50%;
      margin: -2px 10px 0 0;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      transition: .1s ease border-color;
      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: #C77765;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: .1s ease opacity;
      }
    }
    &:checked{
      + span {
        border-color: #C77765;
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
.selectBox{
  border-radius: 5px;
  position: relative;
  & + &{
    margin-top: 10px;
  }
  &:before{
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid $color-bdr01;
    border-right: 1px solid $color-bdr01;
    position: absolute;
    top: 46%;
    right: 20px;
    transform: translateY(-50%) rotate(45deg);
    pointer-events: none;
  }
  select{
    width: 100%;
    padding: 10px 20px;
    @include mq{
      @include sfs(16);
    }
  }
}
.flx-input{
  display: flex;
  @include flx(row, flex-start,center,10px)
  input:not([type="submit"]):not([type="button"]), textarea, select{
    margin-top:0 !important;
    @include mq(pc){
      width: auto;
    }
    
  }
  input[type="radio"],input[type="checkbox"]{
    width: auto;
  }
  .inputhead{
    flex-shrink: 0;
  }
}
.radio-list{
  input[type="radio"]{
    width: auto;
    margin-right:10px;
  }
  textarea{
    margin-top: 10px;
  }
}



//form
.form{
  &-btn{

  }
  &-cont{

  }
  &-agree{

  }
  &-thanks{
    @include mq(pc){
      text-align:center;
      margin: 60px 0;
    }
    dt{
      font-weight: 600;
      @include mq(pc){
        font-size: 2.0rem;
        margin-bottom: 30px;
      }

      @include mq{
        @include sfs(16);
        margin-bottom: calc((20 / 390) * 100vw);
      }

    }
  }

}
//newcard
.newcard{
  &-type{
    @include mq(pc){
      @include flx(row, stretch,stretch,50px);
      width: 100%;

    }

    @include mq{
      @include flx(column, flex-start,flex-start,50px);
    }
    figure{
      text-align: center;
      @include mq{
        margin: calc((15 / 390) * 100vw) 0;
      }
      img{
        height: 100px;
      }
    }
    dl{
      @include mq(pc){
        width:50% ;
        border: 4px solid $color-bdr02;
        border-radius:10px;
        padding: 30px 20px;
        @include flx(column, space-between,center,15px);
      }
    }
    dt{
      font-weight: 700;
      text-align: center;
      font-size: 2.0rem;
      background-color: $color-bg03;
      @include mq{
        @include sfs(18);
      }

    }
    .box-desc{
      margin-bottom: 20px;
      @include mq{
        margin-bottom: calc((15 / 390) * 100vw);
      }
    }
  }
}


//about
.about{
  @include mq(pc){
    text-align:center;
  }

  .dllist_01{
    @include mq(pc){
      @include flx(row, center, flex-start, 0);
    }
    >div{
      @include mq(pc){
        flex: 1;
      }

    }
    dt{
      @include mq(pc){
        height: 50px;
      }

    }
    .flx{
      margin-top: 30px;
      gap: 25px;
      @include mq{
        margin-top: calc((25 / 390) * 100vw);
        gap: calc((25 / 390) * 100vw);
      }
    }
    figure{
      @include flx(column, flex-end, stretch, 10px);
      @include mq{
        gap: calc((8 / 390) * 100vw);
      }
    }
  }
}
//howtoget
.howtoget{
  &_01{
    @include mq(pc){
      text-align: center;
    }
    dl{
      @include flx(column, center, center, );
      @include mq(pc){
        margin-top: 30px;
      }
      @include mq{
        margin-top: calc((30 / 390) * 100vw);
      }
      dd{
        text-align: center;
        font-size: 1.4rem;
        @include mq{
          margin-top: calc((5 / 390) * 100vw);
          @include sfs(13);
        }
      }
    }
  }
  &_02{
    margin-top: calc((30 / 390) * 100vw);
    .cmn-box{
      &:not(:last-child){
        margin-bottom: 60px;
        @include mq{
          margin-bottom: calc((45 / 390) * 100vw);
        }
      }
    }
  }
  .dllist_01{
    .flx{
      margin-top: 30px;
      gap: 30px;
      @include mq{
        margin-top: calc((25 / 390) * 100vw);
        gap: calc((25 / 390) * 100vw);
      }
    }
    figure{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: calc((8 / 390) * 100vw);
    }
  }
}
//howtouse
.howtouse{
  &_01{
    @include mq(pc){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px 50px;
    }
    li{
      &:first-child{
        position: relative;
        &:after{
          content: "";
          display: block;
          background:$main-color;
          clip-path: polygon(0 0, 100% 0, 50% 100%);
          @include mq(pc){
            position: absolute;
            right: -50px;
            transform: rotate(-90deg);
            top: 50%;
            height: 25px;
            width: 50px;
          }
          @include mq{
            height: 13px;
            width: 30px;
            margin: 0 auto 25px;
          }
        }
      }
      .box-desc{
        margin-bottom: 10px;
      }
      .flx{
        gap: 20px;
        margin: 15px auto 0;
        justify-content: center;
        p{
          text-align: center;
        }
      }
    }
  }
  &_02{
    @include mq(pc){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px 50px;
    }
  }
  .dllist_01{
    .flx{
      @include mq(pc){
        gap: 15px;
      }
      @include mq{
        margin-top: calc((25 / 390) * 100vw);
        gap: calc((15 / 390) * 100vw);
      }
    }
    figure{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: calc((8 / 390) * 100vw);
      &:first-child{
        img{
          width: 100px;
          @include mq{
            max-width: calc((56 / 390) * 100vw);
          }
        }
      }
      &:nth-of-type(2){
        flex-shrink: 0;
        img{
          width: 230px;
          @include mq{
            max-width: calc((100 / 390) * 100vw);
          }
        }
      }
    }
  }
}
//forshop
.forshop{
  &_01{
    @include flx(row, center,flex-start,40px);
    @include mq{
      flex-direction: column;
      align-items:center;
    }
    >div{
      @include flx(column, flex-start,center,10px);
      flex: 1;
      dt{
        flex-shrink: 0;
        text-align: center;
        img{
          @include mq(pc){
            height: 100px;
            margin-bottom: 10px;
          }
          @include mq{
            height: 70px;
          }
        }
      }
      dd{
        font-weight: 600;
        font-size: 1.8rem;
        @include mq{
          @include sfs(15);
        }
      }
    }
  }
  &_02{
    font-weight: 600;
    @include flx(row, center,stretch,60px);
    @include mq{
      flex-direction: column;
      align-items:center;
      gap: 40px;
    }
    li{
      @include mq(pc){
        border: 1px solid $main-color;
        padding: 20px;
        border-radius: 10px;
        @include flx(column, space-between,center, 5px)
      }
    }
    span{
      display: block;
      margin-bottom: 10px;
    }
  }
  &_03{
    &.list01{
      @include flx(row, center, center, 40px);
      li{
        margin-top: 0;
        font-size: rem;
        font-weight: 600;
      }

    }
  }
}
.faq{
  &-catlist{
    background-color: $color-bg01;
    margin: 0 calc(50% - 50vw) ;
    padding: calc((15 / 390) * 100vw);
    @include mq(pc){
      @include flx(row, flex-start,stretch,20px)
    }
    h3{
        background: $main-color;
        color: $white;
        border-radius:10px;
      @include mq(pc){
        flex-shrink: 0;
        padding: 10px 30px;
        @include flx(row, center,center,20px)
      }
      @include mq{
        text-align:center;
        padding: 5px;
        margin-bottom: 10px
      }
    }
    ul{
      @include flx(row,center,flex-start,10px);
      flex-wrap: wrap;
      
      @include mq(pc){
        justify-content: flex-start;
      }
      li{
        a{
          display: block;
          background-color: $white;
          border: 1px solid $color-bdr01;
          text-decoration: none;
          border-radius: 30px;
          padding: 0 10px;
          font-size: 1.6rem;
          @include mq{
            @include sfs(13);
          }
        }
      }
    }
  }
  &-list{
    >div{
      & + div{
        border-top:1px dotted $color-bdr01;
      }
      >dt{
        @include flx(row,flex-start,flex-start,10px);
        padding: 20px;
        font-weight: 600;
        @include mq{
          padding:calc((15 / 390) * 100vw);
        }
        &:before{
          width: 30px;
          margin-top: -10px;
          text-align: center;
          content: "Q";
          flex-shrink:0;
          font-weight: 700;
          font-size: 2.4rem;
          @include mq{
            @include sfs(22);
            width: calc((20 / 390) * 100vw);
            margin-top: -5px;
          }
        }
        &:after{
            @include svgarrow(6px, 24px);
            flex-shrink:0;
            margin-left: auto;
            transform: rotate(90deg);
        }
        &._open{
          &:after{
            transform: rotate(270deg);
          }

        }
      }
      >dd{
        display: none;
        >div{
          @include flx(row,flex-start,flex-start,10px);
          // padding:0 20px;
          // max-height: 0px;
          // max-height: 100dvh;
          padding:20px;
          overflow: hidden;
          background-color: #F0F3F5;
          @include mq{
            // padding: 0 calc((15 / 390) * 100vw);
            padding:calc((15 / 390) * 100vw);
          }
          &._open{
            max-height: 100dvh;
            transition: 0.5s;
            padding:20px;
            @include mq{
              padding:calc((15 / 390) * 100vw);
            }
          }
          &:before{
            width: 30px;
            margin-top: -10px;
            text-align: center;
            content: "A";
            flex-shrink:0;
            color: $main-color_b02;
            font-weight: 700;
            font-size: 2.4rem;
            @include mq{
              @include sfs(22);
              width: calc((20 / 390) * 100vw);
              margin-top: -5px;
            }
          }
          >div{
            flex: auto;
            .btn{
              margin-top: 15px;
              justify-content: flex-start;
            }
            .box-contact{
              margin-top: 20px;
            }
          }
        }
        ul.list01{
          padding: 10px 0;
          li + li{
            margin-top: 8px;
          }
        }
        .btn a, .btn input{
          min-width: auto;
          @include mq(pc){
            padding: 15px 30px;
            font-size: 2.0rem;
          }
          @include mq{
            @include sfs(16);
          }
        }
        .btn._reg{
          @include mq{
            padding: 0;
          }
          a,input{
            height: auto;
          }
        }
      }
    }
  }
}
.news{
  &-head{
    h3{
      @include mq(pc){
        font-size: 2.0rem
      }
    }
    >div{
      @include flx(row,flex-start,center,10px);
      margin-bottom: 0;
    }
  }
  &-body{
    margin-top:calc((15 / 390) * 100vw);
  }
  &-img{
    @include mq(pc){
      text-align:center;
    }
  }
}
.event{
  &-slider{
    width: 100vw;
    margin: 0 calc(50% - 50vw) 0;
    padding: 30px 0 80px;
    background-color:$color-bg02 ;

    @include mq{
      margin-top: calc((-30 / 390) * 100vw);
      padding: calc((20 / 390) * 100vw) 0 calc((50 / 390) * 100vw);
    }
    a{
      text-decoration: none;
    }
    figure{
      overflow: hidden;
      aspect-ratio: 1 / 1;
      // aspect-ratio: 376 / 242;
      margin-bottom:10px ;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-list{
    &-wrap{
      margin-top: 50px;
      @include mq(pc){
        @include flx(row, space-between,flex-start,30px)
        flex-wrap:wrap;
      }
    }
    &-blc{
      position: relative;
      border-bottom: 1px solid $color-bdr01;
      padding-bottom: 25px;
      @include mq(pc){
        width: calc(50% - 30px);
      }
      @include mq(){
      }
      &._new{
        &:after{
          content: "NEW";
          position: absolute;
          left: 0;
          top: 0;
          color: $white;
          background-color: $red;
          padding: 3px 10px;
          text-align: center;
          display: block;
          @include mq{
            @include sfs(10);
          }
        }
      }
      & + &{
        @include mq(){
          margin-top: 25px;
        }
        
      }
      a{
        text-decoration: none;
        @include flx(row,flex-start,flex-start,20px);
        @include mq {
          gap:10px;
        }
        figure{
          // aspect-ratio: 4 / 3;
          aspect-ratio: 1 / 1;
          overflow: hidden;
          width:40% ;
          flex-shrink: 0;
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      .cat {
        flex-shrink: 0;
        padding: 3px 10px;
        font-weight:500;
        border-radius: 50px;
        @include mq {
          @include sfs(12);
        }
        .news_list &, .news &{
          @include mq {
            @include sfs(14);
          }
        }

        &._01 {
          background: $cate-color01;
        }

        &._02 {
          background: $cate-color02;
        }
      }
    }
    &-info{
      font-weight: 700;
      @include flx(column,flex-start,flex-start,10px);
      @include lh(16,22);
      .event-slider &{
        padding: 0 calc((10 / 390) * 100vw);
      }
      dt{
        @include flx(column,flex-start,flex-start,5px);
        font-size: 2.0rem;
        @include mq {
          @include sfs(18);
        }
      }
      dd{
        margin-top: auto;
        span{
          &._date{
            font-size: 2.0rem;
            font-weight: 700;
            color: $main-color_b;
            @include mq {
              @include sfs(16);
            }
          }
          &._ptoku{
            display: inline-block;
            padding: 5px;
            background: $main-color_b02;
            color:$white;
            font-size: 1.4rem;
            @include mq {
              @include sfs(10);
            }
          }
        }
      }
    }
  }
}
.feature{
  &-list{
    &-wrap{
      margin-top: 30px;
      @include mq(pc) {
        @include flx(row,flex-start,stretch,40px)
        flex-wrap:wrap;
      }
    }
    &-blc{
      position: relative;
      @include mq(pc) {
        width: calc((100% - 80px)/3);
      }
      @include mq() {
        border-bottom: 1px solid $color-bdr01;
        padding-bottom: 15px;
      }
      &._nekostory{
        background: $white;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 1px 1px 4px 0 rgba(104, 91, 80, 0.1);
        & + &{
          @include mq{
            margin-top:25px;
          }
        }
            a{
              @include mq() {
                display: block;
              }
            dl{
              @include mq() {
                width: 100%;
              }
              dd{
                @include flx(column,flex-start,stretch,10px);
                @include mq() {
                  @include flx(row,flex-start,center,5%);
                  white-space: unset;
                }
              }
            }
        }
      }
      &._latest02{
        a{
          @include flx(column,flex-start,flex-start,10px);
          figure{
            width: 100%;
          }
          .feature-list-info{
            width: 100%;
            dt{
              @include flx(row,flex-start,stretch,10px);
              &:before{
                @include flx(column,center,center,);
                content: "NEW";
                left: 0;
                top: 0;
                color: $white;
                width: 50px;
                background-color: $main-color_b;
                text-align: center;
                @include mq{
                  @include sfs(10);
                }
              }
            }
            dd{
              overflow: hidden;
              text-overflow: unset;
              white-space: unset;
              @include flx(column,flex-start,flex-start,10px);
              @include mq{
                @include sfs(14);
              }
            }
          }
        }
      }
      &._latest{
        @include mq(pc) {
          width: 100%;
        }
        &:after{
          content: "NEW";
          position: absolute;
          left: 0;
          top: 0;
          color: $white;
          background-color: $main-color_b;
          text-align: center;
          display: block;
          @include mq(pc) {
            font-size: 1.8rem;
            padding: 10px 20px;
          }
          @include mq{
            @include sfs(10);
            padding: 3px 10px;
          }
        }
        a{
          @include mq(pc) {
            flex-direction:row;
            gap:50px;
            align-items: center;
          }
          figure{
            width:50% ;
            @include mq(pc) {
              width:40% ;
            }
          }
          .feature-list-info{
            width: calc(50% - 10px);
            @include mq(pc) {
              width: calc(60% - 50px);
              gap: 20px;
            }
            dt{
              @include mq(pc) {
                gap: 15px;
              }
            }
            dd{
              @include line-clamp(3);
              overflow: hidden;
              text-overflow: unset;
              white-space: unset;
              @include mq(pc) {
                font-size: 1.8rem;
              }

            }
          }
        }
      }
      & + &{
        @include mq{
          margin-top: 15px;
        }
      }
      a{
        text-decoration: none;
        @include flx(row,flex-start,flex-start,10px);
        @include mq(pc) {
          flex-direction:column;
        }
        figure{
          aspect-ratio: 948 / 700;
          overflow: hidden;
          width:35% ;
          flex-shrink: 0;
          @include mq(pc) {
            width:100%;
          }
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        
      }
      .cat {
        flex-shrink: 0;
        padding: 3px 10px;
        font-weight:500;
        border: 1px solid $color-bdr01;
        background-color: $gray03;
        @include mq(pc) {
          font-size: 1.4rem;
        }
        @include mq {
          @include sfs(10);
        }
      }
    }
    &-name{
      span{
        display: inline-block;
        &._com{
          @include mq(pc) {
            font-size: 2.0rem;
          }
          @include mq {
            @include sfs(12);
          }
          ._latest &{
            @include mq(pc) {
              font-size: 2.4rem;
            }
            @include mq {
              @include sfs(13);
            }
          }
          ._latest02 &{
            @include mq {
              @include sfs(16);
            }
          }
        }
        &._yaku{
          margin-right: 10px;
          @include mq(pc) {
            font-size: 1.6rem;
          }
          @include mq {
            @include sfs(11);
          }
          ._latest &{
            @include mq {
              @include sfs(12);
            }
          }
          ._latest02 &{
            @include mq {
              @include sfs(13);
            }
          }
        }
        &._name{
          @include mq(pc) {
            font-size: 2.0rem;
          }
          @include mq {
            @include sfs(14);
          }
          ._latest &{
            @include mq(pc) {
              font-size: 2.2rem;
            }
            @include mq {
              @include sfs(16);
            }
          }
          ._latest02 &{
            @include mq {
              @include sfs(20);
            }
          }
        }
      }
      
      ._nekostory &{
        @include flx(row,flex-start,flex-end,15px);
        font-size: 2.0rem;
        @include mq() {
          @include sfs(16)
        }
        >span{padding:0 0 5px;}
      }
    }

    &-info{
      width: calc(65% - 10px);
      @include flx(column,flex-start,flex-start,8px);
      @include lh(16,24);
      @include mq(pc) {
        width:100%;
        gap:10px;
      }
      .event-slider &{
        padding: 0 calc((10 / 390) * 100vw);
      }
      dt{
        font-weight: 700;
        @include flx(column,flex-start,flex-start,5px);
      }
      dd{
        font-weight: 500;
        margin-top: auto;
        color: $gray01;
        width: 100%;
        @include mq {
          @include sfs(11);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p._date{
          text-align:right;
          margin-top:10px;
          @include mq(pc) {
            font-size: 1.4rem;
          }
          @include mq {
            @include sfs(11);
          }
        }
        
      }
    }
  }
}

.kamei{
  &-head{
    background: url(/site/wp-content/uploads/bg-tamaru-scaled.jpg) center top;
    background-size: 100% auto;
    padding: 30px 0;
    .inner{
      @include flx(row,space-between,center,);
      @include mq {
        @include flx(column,center,center,10px);
      }
      >div{
        @include mq(pc) {
          width: 55%;
        }
        @include mq {
          width: 100%;
        }
        flex-shrink: 0;
        >p{
          padding: 30px 10px 0;
          font-weight: 500;
          @include mq {
            padding: 20px 20px 0;
          }
        }
      }
      >img{
        @include mq(pc) {
          width:40% ;
        }
        @include mq() {
          width:60% ;
        }
      }
    }
  }
  &-profile{
    background: #e5f4fc;
    padding: 50px 0;
    border-top: 5px solid $white;
    border-bottom: 5px solid $white;
    @include mq() {
      padding: calc((30 / 390) * 100vw) 0;
    }
    h3{
      text-align: center;
      img{
        @include mq(pc) {
          width: 40%;
        }
        @include mq() {
          width: 80%;
        }
      }
    }
    ul{
      margin-top: 30px;
      @include flx(row,center,center,50px);
      @include mq() {
        @include flx(column,center,center,15px);
        margin-top:calc((15 / 390) * 100vw);
      }
      li{
        @include mq(pc) {
          width: 40%;
        }
        img{width: 100%;}
      }
    }
  }
  &-slider{
    width: 100vw;
    margin: 0 calc(50% - 50vw) 0;
    padding: 50px 0 80px;

    @include mq{
      padding: calc((20 / 390) * 100vw) 0 calc((60 / 390) * 100vw);
    }
    a{
      text-decoration: none;
    }
    figure{
      overflow: hidden;
      margin-bottom:10px ;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-slider-dtl{
    @include mq(pc){
      background:$white;
      width: 100%;
      margin: 0 auto ;
    }

    @include mq{
      margin: 0 auto;
      padding: calc((20 / 390) * 100vw) ;
    }
    figure{
      overflow: hidden;
      aspect-ratio: 100 / 100;
      margin-bottom:10px ;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    figcaption{
      padding:20px;
      @include mq {
        padding:10px;
      }
    }
  }
  &-list{
    &-wrap{
      overflow-x: hidden;
      background: url(/site/wp-content/uploads/icon_neko.svg) no-repeat left 50px top 30px #FAF6EA;
      background-size: auto 80%;
      @include mq(pc) {
        padding: 50px 0;
      }
      @include mq() {
        padding: calc((50 / 390) * 100vw) 0;
        background-position: left -50px top 30px;
        background-size: auto 60%;
      }
    }
    &-blc{
      position: relative;
      background-color: $white;
      overflow:hidden;
      border:3px solid $white;
      @include mq(pc) {
        width: 380px;
        border-width:6px;
        border-radius:30px;
      }
      @include mq() {
        border-radius:calc((20 / 390) * 100vw);
        width: 70%;
      }
      &:hover{
        opacity:0.6;
      }
      &.swiper-slide-active{
        border-color:#2f94e7;
      }
      &._new{
        &:after{
          content: "NEW";
          position: absolute;
          right: -1px;
          top: 0;
          color: $white;
          background-color: #2f94e7;
          text-align: center;
          display: block;
          @include mq(pc) {
            font-size: 1.8rem;
            padding: 10px 20px;
          }
          @include mq{
            @include sfs(10);
            padding: 3px 10px;
          }
        }
      }
      a{
        text-decoration: none;
        padding: 0;
        @include mq(pc) {
        }
        figure{
          aspect-ratio: 100 / 100;
          overflow: hidden;
          flex-shrink: 0;
          width:100%;
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      .cat {
        flex-shrink: 0;
        padding: 3px 10px;
        font-weight:500;
        border: 1px solid $color-bdr01;
        background-color: $gray03;
        @include mq(pc) {
          font-size: 1.4rem;
        }
        @include mq {
          @include sfs(10);
        }
      }
    }
    &-blc02{
      position: relative;
      @include mq(pc) {
        width: 500px;
      }
      @include mq() {
        border-radius:calc((30 / 390) * 100vw);
        width: 85%;
        
      }
    }
    &-info{
      padding: 25px;
      @include mq {
        padding: 15px;
      }
      span{
        font-weight:700;
        display: block;
        margin-bottom:10px;
        &._itemname{
          font-size: 1.8rem;
          @include mq {
            @include sfs(16);
          }
        }
        &._shopname{
          text-align:right;
          font-size: 1.4rem;
          @include mq {
            @include sfs(12);
          }
        }
      }
    }
  }
  &-dtl{
      background: url(/site/wp-content/uploads/icon_neko.svg) no-repeat left 50px top 30px #FAF6EA;
      background-size: auto 80%;
      @include mq(pc) {
        padding: 50px 0;
      }
      @include mq() {
        padding: calc((35 / 390) * 100vw) 0;
        background-position: left -30px top 10px;
        background-size: auto 50%;
      }
  }
  &-iteminfo{
    overflow-x: hidden;
    background: rgba(255,255,255,0.8);
    padding: 30px 30px 50px;
    border-radius: 20px;
    @include mq {
      padding: calc((20 / 390) * 100vw) calc((20 / 390) * 100vw) calc((40 / 390) * 100vw);
    }
    .align-c{
      @include mq {
        text-align:left;
      }
    }
    h3{
      font-size: 2.4rem;
      margin-bottom: 10px;
      @include mq {
        @include sfs(20);
      }
    }
    span{
      &._itemprice{
        font-weight:700;
        display: block;
        margin-bottom:30px;
        font-size: 1.8rem;
        @include mq {
          @include sfs(16);
          margin-bottom:calc((25 / 390) * 100vw);
          text-align: right;
        }
      }
    }
    ._reccmt{
      border: 8px solid #C69C6D;
      position:relative;
      padding: 20px;
      border-radius: 20px;
      margin: 20px auto 0;
      @include mq(pc) {
        width: 60%;
      }
      @include mq {
        border-width: 5px;
        padding: calc((15 / 390) * 100vw);
        margin-bottom: calc((-30 / 390) * 100vw);
      }
      &._daidai{
        img{
          @include mq(pc) {
            padding-top:8px;
          }
          @include mq {
            padding-top:calc((6 / 390) * 100vw);
          }
        }
        
      }
      img{
        position: absolute;
        @include mq(pc) {
          left: 30px;
          top: -47px;
          width: 300px;
        }
        @include mq {
          left: 15px;
          top: -32px;
          width: 200px;
        }
      }
      p{
        @include f_kiwi(500);
        color:#946134;
        font-size: 2.0rem;
        @include mq {
          @include sfs(18)
          @include lh(18,24);
        }
      }
    }
  }
  &-shopinfo{
    margin-top:30px;
    border-top: 1px solid $color-bdr01
    dl{
      padding:30px 0;
    }
    dt{
      font-size: 2.0rem;
      font-weight: 700;
    }
    dd{
      padding: 10px 0 0;
    }
  }
}
.setouchi{
  &-ttl{
    margin-bottom:50px;
    text-align:center;
    background:url(/site/wp-content/uploads/bg_setouchi.jpg) no-repeat bottom center;
    background-size: cover;
    background-color:rgba(255,255,255,0.3);
    background-blend-mode:lighten;
    padding:80px 0;
    @include mq {
      padding: calc((20 / 390) * 100vw) 0;
      margin-bottom:calc((40 / 390) * 100vw);
    }
    img{
      width: 50%;
      max-width:600px;
      margin:0 auto 30px;
      display: block;
      @include mq {
        width: 65%;
        margin-bottom:calc((10 / 390) * 100vw);
        padding-left:calc((20 / 390) * 100vw) ;
      }
    }
    span{
      display: block;
      background:rgba(255,255,255,0.6);
      font-weight: 700;
      padding:20px 0;
      font-size: 1.8rem;
      @include mq {
        padding: calc((5 / 390) * 100vw) 0;
        @include sfs(13)
      }
    }
  }
  &-catch{
    text-align:center;
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom:50px;

  }
  &-head{
    margin: 0 calc(50% - 50vw) 30px;
    @include flx(column,center,center,);
    dl{
      @include flx(column,center,center,10px);
      padding: 20px;
      dt{
        text-align: center;
        ._com{
          font-size: 1.8rem;
          @include mq {
            @include sfs(16);
          }
        }
        ._yaku{
          font-size: 2.0rem;
          @include mq {
            @include sfs(20);
          }
        }
        ._name{
          font-size: 2.4rem;
          @include mq {
            @include sfs(24);
            display:block;
          }
          + ._name{
            @include mq(pc) {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  &-body{
    

  }
  &-banenr{
    @include flx(row,center,stretch,30px);
    border-top: 1px solid $color-bdr02;
    border-bottom: 1px solid $color-bdr02;
    padding: 30px 0;
    margin-top: 60px;
    @include mq {
      @include sfs(16);
      flex-direction:column;
      gap: 10px;
      padding: 15px 0;
      margin-top: calc((30 / 390) * 100vw);
    }
    p{
      text-align:center;
      @include flx(row,center,stretch,);
      @include mq {
      }
      a{
        @include flx(row,stretch,center,);
        border: 2px solid $main-color;
        border-radius:5px;
        padding:15px 30px;
        text-decoration: none;
        width: 100%;
        &:hover{
          opacity: 0.5;
        }
      }
      &._mall{
        a{
          @include flx(column,center,center,5px);
          background:$white ;
        }
        img{
          max-width: 80%;
          max-height: 50px;
        }
      }
      &._site{
        a{
          @include flx(column,center,stretch,0);
          font-size: 1.8rem;
          background:$white ;
        }
      }
      &._icon{
        width: 60px;
        a{
          border:none;
          padding:10px;
          img{width:100%;}
        }
      }
    }
    ._iconwrap{
      @include flx(row,center,center,20px);

    }
  }
}
//common blc contents
._blccontents{
  font-size: 1.8rem;
  @include mq {
    @include sfs(14)
  }
  .cmn-box{
    .cmn-box:not(h4){
      margin-bottom:0;
    }
  }
  h4{
    font-size: 2.0rem;
    background: $cate-color01;
    padding:2px 5px;
    @include mq {
      @include sfs(16)
    }
    &.cmn-box{
      margin-bottom:20px;
    }

  }
  .wp-block-media-text__content{
    @include mq(pc) {
      padding-right:0;
    }
    @include mq {
      padding:10px 0;
    }
  }
  .has-media-on-the-right{
    .wp-block-media-text__content{
      @include mq(pc) {
        padding-right:8%;
        padding-left:0;
      }
    }
  }
  figcaption.wp-element-caption{
      text-align:center;
  }
}
.neko-about{
  &-org{
    >div{
      border-radius:20px;
      padding: 50px 30px;
      border-width: 10px;
      border-style: solid;
      @include mq {
        border-width:4px;
        padding:calc((30 / 390) * 100vw) calc((20 / 390) * 100vw);
      }
      &._aw{
        border-color:#ffdc91;
      }
      &._wanpark{
        border-color:#ffd0d3;
        margin-top: 50px;
        @include mq {
          margin-top:calc((30 / 390) * 100vw);
        }
      }
      h4{
        height: 80px;
        text-align:center;
        margin-bottom: 30px;
        @include mq {
          height: 50px;
        }
        img{
          height: 100%;
          @include mq {
            width: auto;
          }
        }
      }
      .blc-col2{
        gap: 30px;
        @include mq {
          gap:calc((20 / 390) * 100vw) ;
        }
      }
      .dllist_02{
        > div + div {
          margin-top: 10px;
        } 
        dt{
          background-color: #ffe5e6;
          @include mq(pc) {
            width: 300px;
            flex-shrink: 0;
          }
          @include mq {
            margin-bottom: 0;
          }

        }
      }
    }
  }
}
//goldcard
.goldcard{
  &-head{
    figure{
      @include flx(row,center,center,30px);
      @include mq {
        gap:10px;
      }
      img{
        width: 30%;
        @include mq {
          flex: 1;
        }
      }
    }
    .box-ex{
      border:1px solid $key-color02_b;
      dd{
        strong{
          font-size: 2.0rem;
          color: $color-error-text;
          @include mq {
            @include sfs(16)
          }
        }
      }
    }
  }
  &-list{
      @include flx(column, flex-start,stretch,10px);
      &._col02{
        @include mq(pc) {
          @include flx(row, flex-start,stretch,20px);
          flex-wrap: wrap;
          >div{
            width: calc(50% - 20px);
          }
        }
      }
      div{
        background: $color-bg03;
        padding:20px;
        border-radius: 10px;
        dt{
          font-size: 1.8rem;
          font-weight: 700;
          border-bottom:1px solid $key-color03_b;
          padding: 0 0 10px;
          margin-bottom: 10px;
          
          @include mq {
            @include sfs(16)
          }
        }
        dd{
        }
      }
      &._blue{
        div{
          background: $color-bg02;
          dt{
            border-color:$main-color_b;
          }
        }
      }
    }
    &-service{
      padding: 30px;
      text-align: center;
      border: 1px solid #D2AE54;
      outline: solid 1px #D2AE54;
      outline-offset: 4px;
      @include mq(pc) {
        
      }
      @include mq {
        padding: calc((20 / 390) * 100vw);
      }
      img{
        width:100%;
        max-width: 800px;
      }
    }
}
//edy
.edy{
  &-point{
    @include flx(row,stretch,stretch,20px);
    @include mq {
      @include flx(column,center,center,5px);
    }

    dl{
      color: $white;
      padding: 15px;
      border-radius: 10px;
      flex:1;
      @include flx(column,center,center,20px);
      background: #3673c2;
      @include mq {
        width: 100%;
      }
      &._point01{
        background: #e4af03;

      }
      &._point02{
        background: #e27668;
        
      }
      dt{
        border-bottom: 1px solid $white;
        font-size: 2.0rem;
        padding: 0 0 10px;
        font-weight: 500;
      }
      dd{
        text-align: center;
        span{
          font-size: 3.4rem;
          font-weight: 700;
          padding: 0 5px;
        }
      }
    }
  }
}
//town
.tamaruntown{
  color: #956130;
  &-head{
    text-align: center;
    h2{
      margin: 0 auto 40px;
      
      @include mq(pc) {
        width: 65%;
      }
      @include mq {
        width: 90%;
        margin: calc((30 / 390) * 100vw) auto;
      }
    }
    .catch{
      font-weight: 700;
      font-size: 2.0rem;
      @include mq {
        @include sfs(18);
      }
    }
  }
  &-map{
    .inner{
      position: relative;
    }
    &-list{
      @include mq(pc) {
        @include flx(row,space-between,stretch,15px);
        flex-wrap: wrap;
      }
      @include mq {
        @include flx(column,space-between,center,15px);
      }
      a{
        display: block;
        text-decoration: none;
        @include mq(pc) {
          width:calc(33.33% - 15px);
        }
        dl{
          @include mq(pc) {
            height: 100%;
          }
        }
      }
    }
    &-cont{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      a{
        display: block;
        text-decoration: none;
        .tamaruntown-map &{
          position: absolute;
        }
        &._c01{
          top: 45%;
          left: 44%;
          width: 12%;
          dl{
            left: 110%;
            top: 0;
          }
        }
        &._c02{
          top: 17%;
          left: 60%;
          width: 12%;
          dl{
            left: 110%;
            top: 0;
          }
        }
        &._c03{
          top: 36%;
          left: 15%;
          width: 12%;
          dl{
            left: 90%;
            top: -290%;
          }
        }
        &._c04{
          top: 46%;
          left: 72%;
          width: 16%;
          dl{
            left: 6%;
            top: -493%;
          }
        }
        &._c05{
          top: 54%;
          left: 29%;
          width: 9%;
          dl{
            left: -253%;
            top: -23%;
          }
        }
        &._c06{
          top: 85%;
          left: 24%;
          width: 9%;
          dl{
            left: 110%;
            top: 0;
          }
        }
        &._c07{
          top: 78%;
          left: 72%;
          width: 15%;
          dl{
            left: -150%;
            top: -68%;
          }
        }
        &:hover{
          @include mq(pc) {
            img{
              opacity: 0.6;
            }
            dl{
              display: block;
              right: 0;
            }
          }
        }
        dl{
            display: none;
            @include mq(pc) {
              width: 260px;
              position: absolute;
              z-index: 100;
            }
            @include mq(mpc) {
              zoom: 86%;
            }
        }
      }
    }
    &-box{
        border: 4px solid #f9c068;
        border-radius: 10px;
        background:white;
        @include mq {
          border-width: 3px;
        }
        dt{
          background: #f39948;
          border-bottom: 4px solid #f9c068;
          border-radius:6px 6px 0 0;
          color: $white;
          text-align: center;
          padding: 10px 0;
          font-size: 1.8rem;
          font-weight: 500;
          @include mq {
            @include sfs(14);
            padding: calc((5 / 390) * 100vw) 0 ;
            border-width: 3px;
          }
        }
        dd{
          padding: 15px;
          @include mq {
            padding: calc((10 / 390) * 100vw) ;
            @include sfs(12);
          }
        }
    }
  }
  &-profile{
    background: #dceee3;
    padding: 100px 0;
    @include mq {
      padding: calc((60 / 390) * 100vw) 0;
    }
    h3{
      margin:0 auto 60px;
      @include mq(pc) {
        max-width: 70%;
      }
      @include mq {
        margin-bottom: calc((20 / 390) * 100vw);
      }
    }
    ul{
      @include flx(row,space-between,center,50px);
      @include mq {
        @include flx(column,space-between,center,20px);
      }
      li{
        border-radius: 10px;
        overflow: hidden;
        img{
          width: 100%;
        }
      }
    }
  }
}
//neko
.neko{
  background:url(/site/wp-content/uploads/bg_neko-2.jpg);
  background-size: 45% auto;
  background-attachment: fixed;
  position: relative;
  
  @include mq {
    background-size: 60% auto;
  }
  &-side{
    width: 15%;
    position: sticky;
    position: -webkit-sticky;
    top: 150px;
    
    @include mq(mpc) {
      font-size:1.3rem;
    }
    @include mq {
      display: none;
    }
    h2{margin-bottom: 20px;}
    ul{
      li{
        background: url(/site/wp-content/uploads/line_neko.svg) repeat-x left bottom;
        background-size: auto 3px;
        a{
          display: block;
          text-decoration: none;
          color:#965C24;
          padding: 15px 0;
        }
      }
    }
  }
  &-cont{
    max-width: 1100px;
    @include mq(pc) {
      width: 83%;
    }
    @include mq {
      width: 100%;
    }
    &._nekosec{
      background:$white;
      border-radius:20px;
      padding: 40px 30px 60px;
      @include mq {
        padding: calc((15 / 390) * 100vw);
      }
    }
  }
  &-index{
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding: 0 15px;

        @include mq {
          padding: calc((15 / 390) * 100vw);
        }
        &-head{
          border-top: 15px solid #CAAE87;
          background:url(/site/wp-content/uploads/bg_neko-2.jpg);
          background-size: 45% auto;
          @include mq {
            background-size: 60% auto;
          }
          >div{
            @include flx(row,space-between,center,20px);
            background: url(/site/wp-content/uploads/neko_il_btm.svg) repeat-x bottom center;
            background-size: auto 80px;
            @include mq {
              gap:0;
              background-size: auto calc((25 / 390) * 100vw) ;
            }
            >img{
              width: 11.5%;
              margin-bottom: 5%;
              @include mq {
                width: 17%;
              }
            }
            h2{
              width: 60%;
              max-width: 700px;
              padding: 40px 0 140px;
              @include mq {
                padding: calc((25 / 390) * 100vw) 0 calc((55 / 390) * 100vw);
              }
            }

          }
        }
        &-dayan{
          background:$white;
          padding: 20px;
          border-radius:20px;
          @include mq {
            padding: calc((15 / 390) * 100vw);
            border-radius:10px;
          }
          &-ttl{
              text-align: center;
              img{
                width: 68%;
                @include mq {
                  width: 100%;
                }
              }
          }
          >p{
            text-align:center;
            color:#965C24;
          }
          .feature-list-wrap{
            @include mq(pc) {
              padding: 0 30px;
            }
            .feature-list-blc{
              &:nth-child(n+4){
                display: none;
              }
            }
          }
        }
        &-tama{
          background:#ffee9c;
          border-radius: 10px;
          border:2px solid $white;
          padding: 20px;
          @include mq {
            padding: calc((15 / 390) * 100vw);
          }
          >a{
            @include flx(row,flex-start,center,20px);
            text-decoration:none;
            @include mq {
              @include flx(column,center,center,10px);
              position: relative;
            }
            &:after{
              @include svgarrow(16px, 28px, $baseColor: "765300");
              margin-left: auto;
              margin-right: 20px;
              @include mq {
                @include svgarrow(8px, 26px, $baseColor: "765300");
                position: absolute;
                right:  calc((-25 / 390) * 100vw);
                top: 50%;
              }
            }
            figure{
              aspect-ratio: 200 / 76;
              width: 35%;
              overflow: hidden;
              @include mq {
                width: 70%;
              }
              img{
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
            p{
              color: #765300;
              font-size: 1.6rem;
              @include mq {
                padding-right: calc((20 / 390) * 100vw);
                @include sfs(14)
              }
            }
          }
        }
        &-story{
          border-radius: 10px;
          background: url(/site/wp-content/uploads/bg_neko02.svg) no-repeat right bottom;
          background-size: 36% auto;
          &-ttl{
            height: 250px;
            overflow: hidden;
            background:url(/site/wp-content/uploads/bg_hogonekostory-scaled.jpg) no-repeat center bottom 15%;
            background-size: cover;
            @include flx(row,center,center,0);
            backdrop-filter: blur(12px);
            @include mq(pc) {
              border-radius: 10px;
              height: 250px;
            }
            @include mq {
              aspect-ratio: 100 / 40;
              height: auto;
              margin:0 calc((-15 / 390) * 100vw);
            }
            img{
              height: 45%;
              -webkit-filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
              filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));

              @include mq(pc) {
                max-width: 60%;
              }

              @include mq {
                height: auto;
                width: 75%;
                margin-top: -20px;
              }
            }
            ._hogonekostory &{
              @include mq(pc) {
                height: 400px;
                border-radius: 0;
                margin-bottom: 40px;
              }
              @include mq() {
                margin-bottom: 0px;
              }
            }
          }
          .inner{
            padding-bottom: 40px;
            @include mq {
              padding-bottom: calc((20 / 390) * 100vw);
            }
          }
          ._iconvol{
            color: #fff;
            line-height: 1.1;
            aspect-ratio: 32 / 48;
            font-family: filson-pro, sans-serif;
            background: url(/site/wp-content/uploads/icon_neko_y.svg) no-repeat left top;
            font-weight: 500;
            font-style: normal;
            width: 50px;
            @include flx(column,center,center,0);
            flex-shrink: 0;
            font-size: 1.2rem;
            margin: -30px 0 0 -20px;
            @include ls(100)
            &._yellow{
              background: url(/site/wp-content/uploads/icon_neko_y.svg) no-repeat left top;
            }
            &._pink{
              background: url(/site/wp-content/uploads/icon_neko_p.svg) no-repeat left top;
            }
            &._green{
              background: url(/site/wp-content/uploads/icon_neko_g.svg) no-repeat left top;
            }
            &._blue{
              background: url(/site/wp-content/uploads/icon_neko_b.svg) no-repeat left top;
            }
            >span{
              font-size: 2.2rem;
            }
          }
          &-head{
            margin-bottom: 40px;
            @include mq {
              margin-bottom:calc((20 / 390) * 100vw);
            }
            ._iconvol{
              margin: 0;
            }
            .ttl{
              font-size: 2.2rem;
              @include mq {
                @include sfs(16)
              }
            }
            .date{
              display: block;
              text-align: right;
              margin-top: 10px;
            }
          }
        }
        &-contbnr{
          background: url(/site/wp-content/uploads/line_neko.svg) repeat-x left top;
          background-size: auto 6px;
          padding-top: 80px;
          @include mq {
            padding-top: calc((40 / 390) * 100vw);
          }
          >ul{
            @include flx(row,center,flex-start,50px);
            margin-bottom: 40px;
            @include mq {
              flex-direction:column;
              gap:calc((30 / 390) * 100vw) ;
            }
            li{
              flex: 1;
              @include mq(pc) {
                max-width: 60%;
              }
              p{
                margin-top: 20px;
                @include mq {
                  margin-top:calc((15 / 390) * 100vw) ;
                }
              }
            }
          }
        }
        &-contbnr02{
          background: $white;
          padding: 15px;
          border-radius: 10px;
          h4{
            text-align:center;
          }
          ul{
            @include flx(row,center,center,50px);
            @include mq {
              gap: 10px;
            }
            li{
              img{
                padding: 10px;
                background-color: $white;
                height:90px;
                width: auto;
                @include mq {
                  padding: 0;
                  height: auto;
                }
              }
              &:nth-of-type(2){
                img{
                  @include mq {
                    padding: 0 0 0 35px;
                  }
                }
              }
            }
          }
        }
    }
    
}
.abouttama{
  h3{
    width: 60%;
    margin: 0 auto ;
    @include mq {
      width: 75%;
    }
  }
}


//dayan
.dayan{
  &-profile{
    background:$color-bg02 ;
    border-radius:10px;
    padding: 20px;
    h3{
      background: $white;
      text-align:center;
      padding: 10px;
      border-radius:10px;
      margin-bottom: 10px;
    }
    >div{
      gap:30px;
      @include mq {
        @include flx(column,center,center,20px);
      }
    }
    figure{
      width: 42%;
      @include mq {
        width: 60%;
      }
    }
    dl{
      dt{
        font-size: 2.2rem;
        font-weight: 500;
        border-bottom: 1px solid $color-bdr01;
        margin-bottom:20px;
        padding-bottom:10px;
        @include mq {
          @include sfs(16)
          padding: 0 0 10px;
          margin: 0 0 10px;
        }
      }
      dd{
        @include mq {
          @include sfs(12)
        }
        >p{
          text-align:right;
          font-size: 1.0rem;
          @include mq {
            @include sfs(10);
            margin-top: 5px;
          }
        }
      }
    }
  }
}
//
.cmn-flow{
  @include mq(pc) {
    @include flx(row,space-between,stretch,20px);
  }
  @include mq {
    @include flx(column,flex-start,flex-start,20px);
  }
  >dl{
    padding: 20px;
    background: $key-color03;
    @include mq(pc) {
      @include flx(column,flex-start,stretch,20px);
      flex: 1;
    }
    dt{
      font-weight: 700;
      border-bottom: 1px solid $color-bdr01;
      padding: 0 0 10px;
      margin: 0 0 10px;
      @include mq(pc) {
        @include flx(column,stretch,stretch,5px);
      }
      @include mq {
        @include sfs(16);
        @include flx(row,flex-start,center,10px);
      }
      span{
        background: $key-color03_b;

        color: $white;
        padding: 2px 5px;
        flex-shrink:0;
        font-size: 1.3rem;
        @include mq {
          @include sfs(12);
        }
      }
    }
    dd{

    }
  }
}
.app{
  &-feature{
    dl{
      @include mq(pc) {
        @include flx(row,space-between,flex-start,40px);
        flex-wrap: wrap;
      }

      >div{
        border-radius: 5px;
        margin-top: 30px;
        @include mq(pc) {
          width: calc(50% - 20px);
        }
        dt{
          font-weight: 700;
          color: $main-color_b02;
          border-bottom: 1px solid $main-color;
          font-size:2.0rem;
          padding-bottom: 10px;
          margin-bottom: 15px;
          @include mq {
            @include sfs(18);
            padding-bottom: calc((5 / 390) * 100vw);
            margin-bottom: calc((10 / 390) * 100vw);
          }
        }
        dd{
        }
      }
    }
  }
  &-dl{
    @include flx(row,space-between,flex-start,30px);
    @include mq(pc) {
      max-width: 70%;
      margin: 0 auto;
    }
    text-align:center;
    dt{
      font-weight:600;
      @include mq(pc) {
        font-size: 1.8rem;
      }
    }
    dd{
      img{
        margin-top: 10px;
        @include mq(pc) {
          max-width: 50%;
        }
      }
    }
  }
}
.blc_404{
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  @include mq() {
    margin-top: calc((40 / 390) * 100vw) ;
  }
  dt{
    font-size: 3.2rem;
    color: $main-color_b;
    font-weight: 700;
    padding-bottom: 30px;
    border-bottom: 1px solid $main-color;
    @include mq {
      @include sfs(22);
      padding:0 0 calc((30 / 390) * 100vw) 0;
    }
    
  }
  dd{
    font-size: 1.8rem;
    padding: 30px 0;
    @include mq {
      @include sfs(16);
      padding: calc((30 / 390) * 100vw) 0;
    }
  }
}