.nav {
  &-top {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @include mq {
      gap: 14px;
    }

    li {
      width: calc(25% - 20px);
      @include mq {
        width: calc(50% - 7px);
      }

      a {
        aspect-ratio: 160 / 108;
        padding: 10px 0;
        @include flx(column, flex-end, center, 10px);
        text-decoration: none;
        background-color: $white;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        border: 1px solid #CBCBCB;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        @include mq {
          gap: 5px;
        }

        figure {
          flex: auto;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          font-weight: 600;
          font-size: 2.2rem;
          @include lh(17, 17);
          flex-shrink: 0;
          margin-top: auto;
          height: 40px;

          @include mq(lpc) {
            font-size: 2.0rem
          }
          @include mq(spc) {
            font-size: 1.6rem;
            height: 30px;
          }
          @include mq {
            @include sfs(16);
            height: calc((20 / 390) * 100vw);
          }
        }
      }

      &:nth-of-type(1) {
        img {
          width: 35%;
          @include mq {
            width: calc((66 / 390) * 100vw);
          }
        }
      }

      &:nth-of-type(2) {
        img {
          width: 35%;
          @include mq {
            width: calc((62 / 390) * 100vw);
          }
        }
      }

      &:nth-of-type(3) {
        img {
          width: 36%;
          @include mq {
            width: calc((81 / 390) * 100vw);
          }
        }
      }

      &:nth-of-type(4) {
        img {
          width: 30%;
          @include mq {
            width: calc((50 / 390) * 100vw);
          }
        }
      }
    }
  }
}
.swiper{
  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
      @include mq(pc) {
        width: 15px;
        height: 15px;
      }
      @include mq {
        width: calc((10 / 390) * 100vw);
        height: calc((10 / 390) * 100vw);
      }
    }

    .swiper-pagination-bullet {
      background: #E3E3E3;
      opacity: 1;
      @include mq(pc) {
        margin: 0 10px!important;
      }

    }

    .swiper-pagination-bullet-active {
      background: $main-color;
    }

}
.slider {
  &-top {
    @include mq(pc) {
      padding-bottom: 55px;
      margin-bottom:40px;
    }
    @include mq {
      @include inner02;
      padding-bottom: calc((40 / 390) * 100vw);
      margin-top: calc((30 / 390) * 100vw);
      margin-bottom: calc((10 / 390) * 100vw);
    }
    a{
      text-decoration: none;
    }
    figure {
      overflow: hidden;
      @include mq(pc) {
        border-radius: 10px;
        aspect-ratio: 200 /90;
      }
      @include mq {
        aspect-ratio: 337 /210;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-news {
    &-wrap {
      position: relative;

      .swiper-button-prev,
      .swiper-button-next {
        background-color: $white;
        border-radius: 50%;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);

        @include mq(pc) {
          width: 50px;
          height: 50px;
          margin-top: -70px;
        }
        @include mq {
          width: calc((26 / 390) * 100vw);
          height: calc((26 / 390) * 100vw);
          top: calc(calc((80 / 390) * 100vw));
        }
        &:after {
          @include svgarrow(8px, 14px);
        }
      }

      .swiper-button-next {
        right: -25px;
        @include mq {
          right: calc((-10 / 390) * 100vw);
        }
      }

      .swiper-button-prev {
        left: -25px;
        @include mq {
          left: calc((-10 / 390) * 100vw);
        }

        &:after {
          transform: rotate(180deg);
        }
      }
    }

    a {
      text-decoration: none;

      dt {
        @include mq {
          @include sfs(12);
        }
      }

      dd {
        font-weight: 600;

        @include line-clamp(2);
        @include mq {
          @include sfs(16);
        }
      }
    }

    ._fig {
      aspect-ratio: 160 /160;
      margin-bottom: 5px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.list {
  &-event {
    li+li {
      margin-top: 15px;
    }
  }

  &-feature {
    @include mq(pc) {
      @include flx(row, flex-start, flex-start, 40px);
      flex-wrap: wrap;
    }
    li{
      @include mq(pc) {
        width: calc(50% - 20px);
      }

      + li {
        @include mq {
          margin-top: 15px;
        }
      }
    }

    a {
      text-decoration: none;
    }

    ._fig {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 10px;

      span {
        position: absolute;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        color: $white;
        font-weight: 600;
        font-size: 3.0rem;
        @include mq {
          @include sfs(20);
        }
      }
    }

    ._desc {
      @include line-clamp(2);
    }
  }

  &-info {
    @include mq(pc) {
      margin-bottom: 40px
    }
    >div {
      @include flx(row,flex-start,flex-start,20px);
      font-size: 1.8rem;
      margin-bottom: 20px;
      @include mq {
        gap:10px;
        margin-bottom: 15px;
        @include sfs(14);
      }

      .news_list &{
        flex-direction: column;
        border-bottom: 1px solid #BCBCBC;
        padding-bottom: 20px;
        @include mq {
          gap:10px;
          padding-bottom: 15px;
          @include sfs(15);
        }
      }
      .news &{
        margin-bottom: 5px;
        align-items: center;
      }
      a {
        text-decoration: none;
      }

      >dt {
        @include flx(row,flex-start,center,20px);
        flex-shrink: 0;
        @include mq {
          gap:10px;
        }
      }

      >dd {}

      .date {
        flex-shrink: 0;

        @include mq {
          @include sfs(9);
        }
        .news_list &, .news &{
          @include mq {
            @include sfs(14);
          }
        }
      }

      .cat {
        flex-shrink: 0;
        font-weight:500;
        padding: 5px 10px;
        font-size: 1.4rem;
        text-align:center;
        @include mq(pc) {
          width:120px;
        }
        @include mq {
          width:90px;
          @include sfs(12);
          padding: 3px 5px;
        }
        .news_list &, .news &{
          @include mq {
            width:auto;
            @include sfs(14);
          }
        }

        &._01 {
          background: $cate-color01;
        }

        &._02 {
          background: $cate-color02;
        }

        &._03 {
          background: $cate-color03;
        }

        &._04 {
          background: $cate-color04;
        }
      }
    }
  }

  &-bnr {
    @include mq(pc) {
      @include flx(row,center,flex-start,15px);
      margin-bottom: 50px;
      flex-wrap: wrap;
    }
    @include mq {
      @include flx(row,flex-start,flex-start,15px);
      flex-wrap:wrap;
      margin-bottom: 30px;
    }
    li{
      border: 1px solid $color-bdr01;
      @include mq(pc) {
        width:calc(25% - 15px);
      }
      @include mq {
        width:calc(50% - 8px);
      }
      a{
          aspect-ratio: 100 /40;
          display: block;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
      }
    }
  }
}

.top {
  &-feature {}
}

.sec {
  &-town{
    background: #fcf9f4;
    padding: 50px;
    @include mq(pc) {
      margin: 60px calc(50% - 50vw);
      max-width: none;
      width: auto;
    }
    @include mq {
      padding: calc((30 / 390)* 100vw) 0;
    }
    h2{
      text-align:center;
      margin-bottom: 30px;
      img{
        max-width: 400px;
        width: 80%;
      }
    }
    .btn{
      margin-bottom: 20px;
    }
  }
  &-search {
    ._catttl {
      @include mq(pc) {
        font-size: 2.2rem;
        text-align:center;
        margin-bottom: 20px
      }
      @include mq {
        @include sfs(14);
        margin-bottom: calc((8 / 390) * 100vw) ;
      }
    }
    ._chklist{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px 5px;
      >li{
        width: calc((100% - 5px)/2);
      }
    }
    ._taglist {
      display: flex;
      flex-wrap: wrap;
      @include mq(pc) {
        gap: 10px;
        justify-content: center;
      }
      @include mq{
        gap: calc((10 / 390) * 100vw);
      }
      a {
        font-weight: 700;
        display: flex;
        border-radius: 50px;
        text-decoration: none;
        color: #777;
        @include mq(pc) {
          padding: 15px 20px;
        }
        @include mq{
          padding: calc((6 / 390) * 100vw) calc((20 / 390) * 100vw);
        }
      }
      &._area{
        a{
          @include mq(pc) {
            border: 2px solid $color-bdr01 ;
            background-color: $white;
          }
          @include mq {
            background: #EFEFEF;
          }
        }

      }
      &._word{
        a{
          border: 2px solid $main-color ;
          background-color: $white;
        }
      }
    }
    &._top{
      .btn._search{
        // text-indent:20px;
        @include mq(pc) {
          width: 60%;
          margin: 0 auto;
        }
      }
    }
    &-wrap{
      @include mq(pc) {
        @include flx(row, space-between, flex-start, 50px);
      }
      .cmn-box{
        @include mq(pc) {
          flex: auto;
          padding: 30px;
          background: $color-bg01;
          margin-bottom: 0;
          border-radius: 10px;
        }

      }

    }
  }
}