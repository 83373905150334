.form_error_msg{
  @include mq(pc) {
    margin-top: 10px;
  }
  @include mq {
    @include sfs(13);
    margin-top:calc((10 / 390) * 100vw) ;
  }
  li{
    background: $color-error-text;
    color: $white;
    @include mq(pc) {
      padding: 10px 20px;
    }
    @include mq {
      padding: calc((5 / 390) * 100vw) calc((10 / 390) * 100vw);
    }
    + li{
      @include mq(pc) {
        margin-top: -15px;
      }
      @include mq {
        margin-top: calc((-5 / 390) * 100vw);
      }
    }
  }
}
.attention{
  background: $color-error-text;
  color: $white;
  @include mq {
    @include sfs(13);
    padding: calc((5 / 390) * 100vw) calc((10 / 390) * 100vw);
  }
}
//mypage
#mypoint{
  border: 2px solid $color-bdr01;
  @include flx(column, flex-start, center, 20px);
  @include mq(pc) {
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
  @include mq {
    align-items: flex-start;
    gap: 10px;
    padding-bottom: calc((10 / 390) * 100vw);
    margin-bottom: calc((30 / 390) * 100vw);
  }
  >p{
    width: 100%;
    @include flx(row, space-between, center,15px);
    padding: 0 15px;
    font-size: 1.8rem;
    @include mq {
      gap: 5px;
      @include sfs(13);
      padding: 0 calc((15 / 390) * 100vw);
    }
    em{
      font-style: normal;
    }

    &.myname{
      justify-content: center;
      background-color: $color-bg02;
      padding: 10px;
      @include mq {
        padding: calc((10 / 390) * 100vw);
      }
      em{
        font-weight: 700;
        font-size: 2.6rem;
        @include mq {
          @include sfs(18);
        }
      }
    }
    &.point{
      @include mq(pc) {
        width: 65%;
        max-width: 500px;
      }
      strong{
        font-size: 2.6rem;
        color: $red;
        margin-left:auto;
        @include mq {
          @include sfs(18);
        }
      }
      em{
        @include mq {
          @include sfs(11);
        }
      }
    }
  }
  .myp-donation &{
    background:$white;
    @include mq(pc) {
      padding-top: 20px;
    }
    @include mq {
      padding-top: calc((10 / 390) * 100vw);
    }
  }
}
.myPM_set{
  padding: 40px 0;
}

.mypage_mn{
  @include flx(row, flex-start,stretch,30px );
  @include mq(pc) {
    flex-wrap: wrap;
  }
  @include mq {
    gap: 10px;
    flex-wrap: wrap;
  }
  li{
    @include mq(pc) {
      width: calc(33.33% - 20px);
    }
    @include mq {
      width: calc(50% - 5px);
    }
    a{
      @include flx(row, flex-start,center,20px );
      text-decoration: none;
      background: $main-color_b;
      box-shadow: 0px 2px 0px 0px $main-color_b02;
      height: 100%;
      border-radius:10px;
      font-weight: 700;
      color: $white;
      font-size: 1.8rem;
      padding: 7px 5px 7px 20px;
      @include mq {
        @include sfs(12)
        gap:5px;
        padding-left: 10px;
      }
      &:hover{
        background: $main-color_b02;
      }
      &:before{
        content:"";
        width:45px ;
        height:45px ;
        @include mq {
          width:25px;
          height:25px;
        }
      }
    }
    &._mn01{
      a:before{
        background: url(/site/wp-content/uploads/icon_myp_01.svg) no-repeat;
        background-size: contain;
      }

    }
    &._mn02{
      a:before{
        background: url(/site/wp-content/uploads/icon_myp_02.svg) no-repeat;
        background-size: contain;
      }
      
    }
    &._mn03{
      a:before{
        background: url(/site/wp-content/uploads/icon_myp_03.svg) no-repeat;
        background-size: contain;
      }
      
    }
    &._mn04{
      a:before{
        background: url(/site/wp-content/uploads/icon_myp_04.svg) no-repeat;
        background-size: contain;
      }
      
    }
    &._mn05{
      a:before{
        background: url(/site/wp-content/uploads/icon_myp_05.svg) no-repeat;
        background-size: contain;
      }
      
    }
    &._mn06{
      a:before{
        background: url(/site/wp-content/uploads/icon_myp_06.svg) no-repeat;
        background-size: contain;
      }
      
    }
  }
}
//common
.tbl_form_style{
  width: 100%;
  background-color: $white;
  tr{
    border-bottom: 1px solid $color-bdr01;
    @include mq {
      @include flx(column,flex-start,flex-start,5px);
    }
  }
  th{
    padding:10px 20px;
    text-align:left;

  }
  td{
    padding:10px 20px;
    input:not([type="submit"]):not([type="button"]), textarea, select{
      width: auto;
      margin-top: 0!important;
    }
  }
}
.tbl_point_history{
  width: 100%;
  background-color: $white;
  tr{
    border-bottom: 1px solid $gray03 ;
    &:nth-child(odd){
      background:$color-bg03;
    }
  }
  th{
    padding: 10px 15px;
    background-color: $key-color02_b;
    text-align:left;
    @include mq {
      padding: 8px;
    }
    &:not(:last-child){
      border-right: 1px solid $gray03 ;
    }
    a{
      color:$white;
      &:hover{
        text-decoration:none;
      }
    }
  }
  td{
    padding: 10px 15px;
    @include mq {
      padding: 8px;
    }
  }
}
.tbl_scr{
  @include mq {
    overflow-x: auto;

  }
  table{
    @include mq {
      width: 600px;

    }
  }
}

//icoca
.icoca{
  &-head{
    @include flx(row, space-between,center,30px );
    margin-bottom:20px;
    @include mq {
      @include flx(column, space-between,stretch,20px );

    }
  }
}
.pagination {
  @include flx(row,center,center,10px);
  font-weight: 700;
  padding: 20px 0;
  @include mq{
    @include sfs(18);
    padding:calc((20 / 390) * 100vw) 0;
  }
    li {
      span{
        @include flx(row,center,center,);
        padding: 5px;
        width: 100%;
        height: 100%;
        position: relative;
      }
      a {
        color:$gray01;
        text-decoration: none;
        &.active{
          color: $main-color_b02
        }
        img{
          display:none;
        }
      }
      &:last-child, &:first-child{
        a{
          @include flx(row,center,center,);
          position:relative;
          border: 1px solid $gray03;
          border-radius: 50%;
          background:#FAFAFA;
          opacity: 1;
          width: 40px;
          height: 40px;
          &:before{
            @include svgarrow(8px ,14px)
            position: absolute;

          }
          &:hover{

          }
        }
      }
      &:first-child{
        a:before{
          transform: rotate(180deg);
        }
      }
    }
}
//
.com_calendar_icon{
  width: 40px;
  height:40px;
  border-radius:5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: url(/site/wp-content/uploads/icon_cal.svg) no-repeat center center $main-color_b;
  background-size: 30px 30px;
  
}

//mypage contents
.myp{
  &-donation{
    &-head{
      h4{
         text-align: center;
         color: #37b12d;
         font-size: 3.0rem;
         margin-bottom:40px;
         padding-bottom:40px;
         border-bottom:2px solid #37b12d;
         @include mq{
           @include sfs(20);
           margin-bottom:calc((30 / 390) * 100vw);
           padding-bottom:calc((30 / 390) * 100vw);
         }
      }
      p.cmn-box{
        font-size: 1.8rem;
        @include mq{
          @include sfs(1.6);
        }


      }
    }
    &-blc{
      @include flx(row, space-between,stretch,50px );
      margin-top: 50px;
      @include mq{
        margin-top:calc((30 / 390) * 100vw);
        @include flx(column, flex-start,flex-start,30px );
      }
      dl{
        border:2px solid #37b12d;
        border-radius:10px;
        padding: 25px;
        height:100%;
        @include flx(column, stretch,center,10px );
        dt{
          height: 60px;
          img{
            width:auto;
            height:100%;
          }
        }
        dd{
          p.align-r{
            padding:10px 0;
            @include mq{
              text-align:center;
            }
          }
        }
      }
    }
    &-form{
      >div{
        @include flx(row, center,center,30px );
        @include mq{
          @include flx(column, flex-start,center,20px );
        }
        dt{
          font-size: 2.0rem;
          font-weight: 700;
          @include mq{
            @include sfs(18);
          }
        }
        dd{
          font-size: 1.6rem;
          @include mq{
            text-align: center;
          }
          input{
            margin-right: 10px;
            @include mq{
              width:70% !important;
            }
          }
        }
      }
    }
  }
}