// ttl
.ttl_01{
  font-size: 3.0rem;
  @include lh(20,27);
  font-weight: 600;
  margin-bottom: 50px;
  @include flx(row, space-between, center, 30px)
  @include mq{
    @include sfs(20);
    gap: 10px;
    margin-bottom: calc((20 / 390) * 100vw);
  }
  span{
    flex-shrink: 0;
  }
  &:before, &:after{
    content:"";
    height: 2px;
    width: 100%;
    display: block;
    background-color: $main-color;
  }
}
.ttl_02{
  font-size: 3.0rem;
  font-weight: 600;
  @include ls(30);
  margin-bottom: 40px;
  position: relative;
  text-align: center;
  @include mq{
    @include sfs(22);
    margin-bottom: calc((18 / 390) * 100vw);
  }
}
.ttl_03{
  font-size: 2rem;
  font-weight: 600;
  @include ls(30);
  margin-bottom: 30px;
  display: flex;
  gap: 13px;
  @include mq{
    @include sfs(20);
    @include lh(22,27);
    margin-bottom: calc((18 / 390) * 100vw);
  }
  ._green &{
    @include mq{
      margin-left: calc((-20 / 390) * 100vw);
      margin-right: calc((-20 / 390) * 100vw);
    }
  }
  ._green &{
    @include mq{
      @include sfs(19);
    }
  }
  >span{
    color: $white;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include mq{
      @include sfs(20);
      @include lh(20,27)
    }
    ._green &{
      margin-top: -10px;
      padding-bottom: 3px;
      background: url(../img/parts/icon_num_g.svg) no-repeat left center;
      background-size: contain;
      @include mq(pc){
        width: 53px;
        height: 50px;
      }
      @include mq{
        width: calc((53 / 390) * 100vw);
        height: calc((50 / 390) * 100vw);
      }
    }
    ._pink &{
      background: url(../img/parts/icon_num_p.svg) no-repeat left center;
      background-size: contain;
      @include mq(pc){
        width: 52px;
        height: 41px;
      }
      @include mq{
        width: calc((52 / 390) * 100vw);
        height: calc((41 / 390) * 100vw);
      }
    }
  }
}
.sttl_01{
  font-size: 2.0rem;
  font-weight: 600;
  border-bottom: 1px solid $color-bdr01;
  padding-bottom: 15px;
  margin-bottom: 30px;
  @include mq{
    @include sfs(18);
    @include lh(18,26);
    padding-bottom: calc((15 / 390) * 100vw);
    margin-bottom: calc((15 / 390) * 100vw);
  }

}
.sttl_02{
  font-size: 1.8rem;
  font-weight: 600;
  background-color: $gray02;
  border-radius: 5px;
  padding: 10px 1em;
  margin-bottom: 20px;
  @include mq{
    @include sfs(16);
    @include lh(18,26);
    padding: calc((5 / 390) * 100vw) calc((15 / 390) * 100vw);
    margin-bottom: calc((15 / 390) * 100vw);
  }

}
.sttl_03{
  font-size: 2rem;
  font-weight: 600;
  padding: 0 ;
  @include mq{
    @include sfs(15);
    @include lh(18,26);
    margin-bottom: calc((15 / 390) * 100vw);
  }

}
.catch{
  display: block;
  margin-top: calc((5 / 390) * 100vw);
  font-size: 1.8rem;
  @include mq{
    @include sfs(14);
  }

}
//flex
.flx{
  display: flex;
  &._center{
    justify-content: center;
  }
  &._top{
    align-items: flex-start;
  }
  &._middle{
    align-items: center;
  }
}
// button
.btn{
  @include mq(pc){
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include mq{
    width: 100%;
    @include sfs(20);
  }
  a,input{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 80px;
    font-weight: 600;
    gap:10px;
    @include mq(pc){
      padding: 20px 50px;
      min-width: 50%;
    }
    @include mq{
      padding: 7px 10px;
    }
  }
  input{
    @include mq(pc){
      font-size: 2.4rem;
    }
    @include mq{
      width: 100%;
      @include sfs(20);
    }
  }
  &._sbtn:not(._search){
    a,input{
      @include mq(pc){
        padding: 15px 30px;
        font-size: 2.0rem;
      }
      @include mq{
        padding: 8px 0;
        @include sfs(16);
      }
    }
  }
  &._ssbtn{
    a,input{
      @include mq(pc){
        padding: 10px 20px;
        font-size:1.8rem;
      }
      @include mq{
        padding: 3px 10px;
        @include sfs(13);
      }
    }
  }
  &._gray{
    a,input{
      background:$gray01;
      color:$white;
      &:hover{
        @include mq(pc){
          opacity: 0.6;
        }
      }
    }
  }
  &._gray02{
    a,input{
      background:$gray02;
      &:hover{
        @include mq(pc){
          opacity: 0.6;
        }
      }
    }
  }
  &._blue{
    a,input{
      background:$main-color;
      color:$white;
      &:disabled{
        background-color: $color-disabled-bg;
        color: $color-disabled-text;
      }
      &:hover{
        @include mq(pc){
          opacity: 0.6;
        }
      }
    }
  }
  &._green{
    a,input{
      background:$key-color01;
      color:$white;
      gap: 20px;
      &:hover{
        @include mq(pc){
          opacity: 0.6;
        }
      }
    }
    a{
      &:after{
          @include svgarrow(8px, 14px, $baseColor: "fff");
      }
    }
  }
  &._pink{
    a,input{
      background:$key-color03_b;
      color:$white;
      gap: 20px;
      &:after{
          @include svgarrow(8px, 14px, $baseColor: "fff");
      }
      &:hover{
        @include mq(pc){
          opacity: 0.6;
        }
      }
    }
  }
  &._brown{
    a,input{
      background:#956134;
      color:$white;
      &:disabled{
        background-color: $color-disabled-bg;
        color: $color-disabled-text;
      }
      &:hover{
        @include mq(pc){
          opacity: 0.6;
        }
      }
    }
  }
  &._bdr{
    a,input{
      border:2px solid $main-color;
      background:$white;
      &:hover{
        @include mq(pc){
          background:$main-color;
          color: $white;
        }
      }
    }
  }
  &._person{
    &:not(._sbtn){
      @include mq{
        @include sfs(23);
      }
    }
    a{
      &:before{
          @include icon('icon_person.svg', 15px, 27px)
      }
    }
  }
  &._shop{
    a{
      &:before{
          @include icon('icon_shop.svg', 34px, 27px)
      }
    }
  }
  &._reset{
    a,input{
      padding: 15px 15px;
      color: $gray01;
    }
  }
  &._search:not(._ssbtn){
    a{
      width: 100%;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      @include mq{
        @include sfs(16);
      }
      &:before{
          @include icon('icon_search.svg', 24px, 24px);
      }
    }
    input{
      padding: 15px 20px 15px 50px;
      @include mq{
        @include sfs(16);
      }
    }
  }
  &._sort{
    a{
      &:before{
          @include icon('icon_sort.svg', 24px, 24px)
      }
    }
  }
  &._web{
    a{
      &:before{
          @include icon('icon_web.svg', 34px, 27px)
      }
    }
  }
  &._cmt{
    a{
      &:before{
          @include icon('icon_cmt.svg', 34px, 27px)
      }
    }
  }
  &._reg{
    @include mq{
      @include sfs(15);
      padding: 0 calc((15 / 390) * 100vw);
    }
    a,input{
      background-color: $key-color02;
      color: $white;
      justify-content: flex-start;
      @include mq{
        @include sfs(16);
        @include lh(16,20);
        padding-left: calc((40 / 390) * 100vw);
        height: calc((58 / 390) * 100vw);
      }
      >span{
        display: flex;
        flex-direction: column;
        >span{
          @include mq{
            @include sfs(13);
          }
        }
      }
    }
  }
  &._about{
    @include mq{
      @include sfs(15);
    }
    a{
      &:before{
          @include icon('icon_nav01_s.svg', 34px, 27px);
          @include mq(pc){
            @include icon('icon_nav01_s.svg', 50px, 40px);
          }
      }
      &:after{
          @include svgarrow(8px, 14px);
      }
    }
  }
  &._get{
    @include mq{
      @include sfs(15);
    }
    a{
      &:before{
          @include icon('icon_nav02_s.svg', 34px, 27px);
          @include mq(pc){
            @include icon('icon_nav02_s.svg', 50px, 40px);
          }
      }
      &:after{
          @include svgarrow(8px, 14px);
      }
    }
  }
  &._use{
    @include mq{
      @include sfs(15);
    }
    a{
      &:before{
          @include icon('icon_nav03_s.svg', 34px, 27px);
          @include mq(pc){
            @include icon('icon_nav03_s.svg', 50px, 40px);
          }
      }
      &:after{
          @include svgarrow(8px, 14px);
      }
    }
  }
  &._zip{
    a,input{
      @include mq(pc){
        padding: 7px 15px;
        font-size:1.3rem;
      }
      @include mq{
        padding: 3px 10px;
        @include sfs(11);
        gap: 5px;
      }
    }
  }
}
.btn-box{
  margin-top: 30px;
  @include flx(row,center,center,10px)
}
.btn-list{
  @include mq(pc){
    margin-top: 50px;
    @include flx(row,center,center,50px)
  }
  @include mq{
    margin-top: calc((30 / 390) * 100vw) ;
  }
  li{
    + li{
      @include mq{
        margin-top: calc((15 / 390) * 100vw) ;
      }
    }
  }
  p + p{
      @include mq{
        margin-top: calc((15 / 390) * 100vw) ;
      }
  }
}
//btnfooter
.btn-ft{
  background: $gray02;
  padding: 50px 30px;
  margin: 80px calc(50% - 50vw) -140px;
  @include mq{
    padding: calc((30 / 390) * 100vw) calc((16 / 390) * 100vw);
    margin-top: 35px ;
    margin-bottom: -35px;
  }
  ul{
    @include flx(row, center, center,30px );
    @include mq{
      flex-direction:column;
      gap: calc((15 / 390) * 100vw) ;
    }

    li{
      a{
        background-color: $white;
        margin: 0 auto;
        justify-content: flex-start;
        @include mq{
          height: calc((44 / 390) * 100vw);
          width: calc((250 / 390) * 100vw);
          padding:0 calc((30 / 390) * 100vw) ;
        }
        &:after{
          margin-left: auto;
        }
      }
      &.btn{
        .about &{
          &._about{display: none;}
        }
        .howtouse &{
          &._use{display: none;}
        }
        .howtoget &{
          &._get{display: none;}
        }
      }
    }
  }
}
// dllist
.dllist_01{
  text-align: center;
  @include mq{

  }
  >div{
    + div{
      @include mq{
        margin-top:calc((40 / 390) * 100vw);
      }
    }
  }
  dt{
    font-weight: 700;
    font-size: 2.2rem;
    @include mq{
      @include sfs(19);
      margin-bottom:calc((15 / 390) * 100vw);

    }
    .about &{
      color: $key-color02_b;
    }

  }
  dd{
    @include mq(pc){
      font-size: 1.8rem

    }
  }
}

.dllist_02{
  >div{
    @include flx(row,flex-start,stretch,15px)
    @include mq{
      @include flx(column,flex-start,stretch,10px)
    }
    + div{
      margin-top:30px;
      @include mq{
        margin-top:calc((40 / 390) * 100vw);
      }
    }
  }
  dt{
    font-weight: 700;
    flex-shrink: 0;
    background-color: $color-bg01;
    padding: 10px 15px;
    @include mq{
      margin-bottom:calc((15 / 390) * 100vw);

    }

  }
  dd{
    flex-basis: 1;
    padding: 10px;
    @include mq{
      padding: 0 15px;

    }
  }
}
//blc
.blc{
  &-col2{
    @include mq(pc){
      @include flx(row,flex-start,flex-start,15px);
    }
    @include mq{
      @include flx(column,flex-start,flex-start,15px);
    }
    &._right{
      @include mq(pc){
        @include flx(row-reverse,flex-start,flex-start,15px);
      }
    }
    &._center{
      @include mq{
        align-items:center;
      }
    }
    figure{
      @include mq(pc){
      width: 45%;
      flex-shrink: 0;
      }
      &._w30{
        @include mq(pc){
        width: 30%;
        }
        
      }
    }
  }
}
//tbl
.tbl_style{
  width: 100%;
  margin-bottom: 10px;
  th,td{
    border: 1px solid $color-bdr01;
    text-align: center;
    padding: 5px;
  }
  thead{
    th{
      background: $color-bg04;
    }
  }
  tbody{
    tr{
      &._ex{
        th{background:$key-color03;}
        td{background:#fff5f2;}
      }

    }
    th{
      background: $color-bg04;
    }
    td{
    }
  }
  ._note{
    font-size: 0.8em;
    display: block;
  }
  ._num{
    font-size: 1.4em;
    font-weight: 700;
    color: $color-error-text;
  }

}
//pager
.cmn-pager {
  padding:calc((20 / 390) * 100vw) 0;
  @include mq{
    @include sfs(18);
  }
  ul {
    @include flx(row,center,center,10px);
    font-weight: 700;
    &._single{
      @include flx(row,space-between,center,20px);
      font-weight:normal;
      li{
        max-width: 50%;
        &.next{
          margin-left: auto;
        }
      }
    }
    li {
      a,span {
        @include flx(row,center,center,10px);
        padding: 5px;
        width: 100%;
        height: 100%;
        position: relative;
        color:$gray01;
        text-decoration: none;
        &.active{
          color: $main-color_b02
        }
      }
      &.prev ,&.next{
        a{
          color:$text-color;
          @include mq{
            @include sfs(12);
          }
          &:after{
            content:"";
            border: 1px solid $gray03;
            border-radius: 50%;
            background:#FAFAFA;
            opacity: 1;
            width:40px;
            height:40px;
            flex-shrink: 0;
            @include mq{
              width:30px;
              height:30px;
            }
          }
          &:before{
            @include svgarrow(8px ,14px)
            position: absolute;

          }
          &:hover{
              opacity: 0.5;
          }
        }
      } 
      &.prev{
        a{
          flex-direction: row-reverse;
          &:before{
            transform: rotate(180deg);
            left: 20px;
            @include mq{
              left:15px;
            }
          }
        }
      }
      &.next{
        a{
          &:before{
            right: 20px;
            @include mq{
              right:15px;
            }
          }
        }
      }
    }
  }
}


// box
.box{
  &-desc{
    & + &{
      margin-top: 30px;
    }
  }
  &-note{
    margin-top: 15px;
    @include mq{
      @include sfs(13)
    }
    & + .box-desc{
      margin-top: 30px;
    }
  }
  &-ex{
    margin-top: 15px;
    padding: 30px;
    background-color: $color-bg04;
    border-radius: 4px;
    @include mq{
      padding:calc((20 / 390) * 100vw);
    }
    & + .box-desc{
      margin-top: 30px;
    }
  }
  &-alert{
    padding: 10px;
    border: 2px solid $color-error-text;
    color: $color-error-text;
    border-radius: 4px;
    font-weight: 600;
    @include mq{
      @include sfs(13)
    }
    &-bg{
      padding: 10px;
      border-radius: 4px;
      font-weight: 600;
      background-color: $color-error-text;
      color: $white;
    }
  }
  
  &-cardno{
    margin-top: 20px;
    padding: 15px;
    border: 2px solid $color-bdr02;
    background-color: $color-bg04;
    font-weight: 600;
    font-size:2.0rem;
    border-radius: 4px;
    text-align:center;
    @include mq{
      @include sfs(16)
    }
    & + .box-desc{
      margin-top: 30px;
    }
  }
  &-termtxt{
    overflow-y: auto;
    border: 1px solid $color-bdr01;
    border-radius: 4px;
    padding: 20px;
    height: 40vw;
    background-color: $white;
  }
  &-contact{
    margin-top: 50px;
    text-align: center;
    font-weight: 700;
    font-size: 2.0rem;
    @include mq{
      @include sfs(15);
    }

    dt{
      color: $main-color;
      font-size: 2.4rem;
      margin-bottom: 15px;
      @include mq{
        @include sfs(19);
        margin-bottom:calc((5 / 390) * 100vw);
      }
    }
    ._tel{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-size: 3.2rem;
      @include mq{
        @include sfs(25);
        margin-bottom:calc((15 / 390) * 100vw);
      }
      &:before{
        @include icon('icon_tel.svg', 22px, 22px);
        @include mq(pc){
          @include icon('icon_tel.svg', 40px, 40px);
        }
      }
    }
  }
}

// clm
.clm02{
  display: flex;
  gap: 1.7vw 2.5%;
  flex-wrap: wrap;
  align-items: flex-start;
  @include mq{
    gap: 4.8vw 2.5%;
    flex-direction: column;
  }
  >*{
    width: calc(97.5% / 2);
    @include mq{
      width: 100%;
    }
  }
  >.tgl02{
    @include mq{
      margin-top: 0;
    }
  }
  + .cmn-item{
        margin-top:30px;
        @include mq{
            margin-top: calc((25 / 390) * 100vw);
        }
  }
}
.clm03{
  display: flex;
  gap: 1.7vw 2.5%;
  flex-wrap: wrap;
  @include mq{
    gap: 4.8vw 2.5%;
    flex-direction: column;
  }
  >*{
    width: calc(95% / 3);
    @include mq{
      width: 100%;
    }
  }
  &.tgl01{
    @include mq{
      gap: 0;
    }
  }
}
.clm04{
  display: flex;
  gap: 1.7vw 2.5%;
  flex-wrap: wrap;
  @include mq{
    gap: 4.8vw 2.5%;
  }
  >*{
    width: calc(92.5% / 4);
    @include mq{
      width: calc(97.5% / 2);
    }
  }
}
.clm05{
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  gap:5%;
  @include mq{
    gap: 7vw;
  }
  >*{
    flex:1;
    max-width:334px;
    @include mq{
      width: calc(100%);
    }
  }
}

.align{
  &-r{
    text-align: right;
  }
  &-l{
    text-align: left;
  }
  &-c{
    text-align: center;
    &-pc{
      @include mq(pc){
        text-align: center;
      }
    }
  }
}
figure{
  &._capimg{
    img{
      border: 1px solid $color-bdr01;
      padding: 10px;
    }
  }
}
// list
.list01{
  // margin-left:1em;
  li{
    margin-left:1.5em;
    & + li{
      margin-top: 15px;
    }
  }

  &._disc{
    list-style-type: disc;
  }
  &._decimal{
    list-style-type: decimal;
  }

  &._roman{
    list-style-type: lower-roman;
  }
  &._note{
    li{
      text-indent: -1.0em;
    }
  }
  &._num{
    counter-reset: my-counter;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding-left: 1.5em;
      margin-left: 0;
      position: relative;
      &:before {
        content: counter(my-counter);
        counter-increment: my-counter;
        border: 1px solid $text-color;
        border-radius: 50%;
        box-sizing: border-box;
        @include flx(row, center,center,);
        position: absolute;
        height: 16px;
        width: 16px;
        line-height: 1;
        font-size: 75%;
        top: 4px;
        left: 0;
      }
    }
  }
  &._file{
    li{
      @include flx(row, flex-start,flex-start,10px);
      margin-left:0;
      &:before{
        @include icon('icon_pdf.svg', 22px, 22px);
        flex-shrink: 0;
      }
    }
  }
}

//transition fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

//block cont only
._blccontents{
 ul{
  margin-left: 1.5em;
 } 
 .cmn-box + .wp-block-image, .wp-block-image + .wp-block-image{
    margin-bottom: 60px;
    @include mq{
      margin-bottom: calc((40 / 390) * 100vw);
    }
}

}
.cmn-box.wp-block-columns, .cmn-box.wp-block-snow-monkey-blocks-media-text {
  >.cmn-box{
    margin-bottom:0;
  }
}