header{
  border-bottom: 1px solid $color-bdr01;
  background: $white;
  width: 100%;
  position: fixed;
  z-index: 10;
  @include mq(pc){
    background-color: $white;
    height: $pc-header-height;
    @include flx(column, space-between, stretch, );
  }
  @include mq{
    height: $sp-header-height;
  }
  .logo{
    @include mq(pc){
      height: 40px;
    }
    @include mq{
      height: calc((30 / 390) * 100vw);
    }
    img{
      height: 100%;
    }
  }
  .inner{
    @include flx(row, space-between, center, );
    @include mq(pc){
      flex:auto;
    }
    @include mq{
      align-items: center;
      height: $sp-header-height;
    }
  }
  .btn.pc{
    font-size: 1.6rem;
    a{padding: 5px 30px;}
  }
}
.pc-nav{
  &-blc{
    border-top: 1px solid $color-bdr01;
    @include mq{
      display: none;
    }
    a{
      text-decoration: none;
    }
  }
  &_list{
    @include flx(row, center, center, 10px);
    @include inner;
    >li{
      position: relative;
      width: 18%;
      text-align: center;
      &:hover{
        >ul{
          display: block;
        }
      }
      a, span{
        padding: 10px;
        display: block;
        font-weight: 600;
        transition: 0.5s;
        &:hover{
          background-color:$main-color;
        }
      }
      >ul{
        display: none;
        position: absolute;
        top: 44px;
        background-color: $white;
        width: 100%;
        >li{
          border-top: 1px solid $color-bdr01;
          a{
            font-weight: 500;
            font-size: 1.4rem;
            padding: 15px 0;
            &:hover{
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
  &_sub{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 50%;
      @include mq{
        &:nth-child(odd){
          border-right: 1px solid $color-bdr01;
        }
      }
      a{
        padding: 10px;
        @include flx(row, center, center, 0);
        border-bottom: 1px solid $color-bdr01;
        @include mq{
          @include sfs(14)
        }
      }
    }
  }
}

.sp-infobtn{
    @include mq(pc){
      display: none;
    }

}


.sp-nav{
  &-btn{
    header &{
      @include mq(pc){
        display: none;
      }
    }
    position: relative;
    @include mq(pc){
      width: 50px;
      height: 25px;
      cursor: pointer;
    }
    @include mq{
      width:calc((33 / 390) * 100vw);
      height:calc((24 / 390) * 100vw);
    }
    span{
      background: $main-color;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      position: absolute;
      transition-duration: 0.5s;
      &:nth-of-type(1){
        top: 0;
      }
      &:nth-of-type(2){
        @include mq(pc){
          display: none;
        }
        @include mq{
          top: calc((10 / 390) * 100vw);
        }
      }
      &:nth-of-type(3){
        bottom: 0;
      }
    }
    &[data-open="true"],&[data-searchopen="true"]{
      span{
        &:nth-of-type(1){
          transform: rotate(45deg);
          @include mq(pc){
            top: 10px;
          }
          @include mq{
            top: calc((10 / 390) * 100vw);
          }
        }
        &:nth-of-type(2){
          opacity: 0;
        }
        &:nth-of-type(3){
          transform: rotate(-45deg);
          @include mq(pc){
            bottom: 10px;
          }
          @include mq{
            bottom: calc((10 / 390) * 100vw);
          }
        }
      }

    }
  }
  &-blc{
    @include mq(pc){
      display:none;
    }
    z-index: 10;
    position:fixed;
    top: $sp-header-height;
    left: 0;
    background: $white;
    width: 100vw;
    height: calc(100dvh - 50px);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    transition-duration: 0.5s;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    // &._open{
    //   display:block;
    // }
    &[data-open="true"]{
      opacity: 1;
      visibility: visible;
    }
    a{
      text-decoration: none;
    }
  }
  &_reg{
    display: flex;
    gap: 5%;
    padding: 20px;
    flex-wrap: wrap;
    >li{
      width: 30%;
      a{
        @include flx(column, center, center, 5px)
        border-radius: 10px;
        background-color: $main-color_b;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        color: $white;
        padding: 10px 0;
        font-weight: 500;
        aspect-ratio: 1/1;
        @include mq{
          @include sfs(13)
        }

      }
      // &._long{
      //   width: 100%;

      // }
      &._login{
        a:before{
          @include icon('icon_login.svg', 40px, 40px);
          flex-shrink:0;
        }
      }
      &._reg{
        a:before{
          @include icon('icon_reg.svg', 40px, 40px);
          flex-shrink:0;
        }
      }
      &._card{
        a:before{
          @include icon('icon_card.svg', 40px, 40px);
          flex-shrink:0;
        }
      }
    }
  }
  &_list{
    >li{
      border-bottom: 1px solid $color-bdr01;
      a, span{
        padding: 20px;
        @include flx(row, space-between, center, 5px);
        font-weight: 600;
        @include mq{
          @include sfs(15)
        }
      }
      span{
        &:after{
            @include icon('icon_plus.svg', 20px, 20px);
            flex-shrink:0;
        }
        &._open{
          &:after{
              @include icon('icon_minus.svg', 20px, 20px);
          }

        }
      }
      a{
        &:after{
            @include icon('icon_arrow.svg', 20px, 20px);
            flex-shrink:0;
        }
      }
      >ul{
        display: none;
        padding-left: 1em;
        padding-bottom:10px;
        &._open{
          display: block;
        }
        >li{
          a{
            @include mq{
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
  &_sub{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 50%;
      @include mq{
        &:nth-child(odd){
          border-right: 1px solid $color-bdr01;
        }
      }
      a{
        padding: 10px;
        @include flx(row, center, center, 0);
        border-bottom: 1px solid $color-bdr01;
        @include mq{
          @include sfs(14)
        }
      }
    }
  }
}

.search-blc{
  z-index: 100;
  position:fixed;
  background: $white;
  transition-duration: 0.5s;
  border: 5px solid $main-color;
  @include inner;
  opacity: 0;
  visibility: hidden;
  // &._open{
  //   display:block;
  // }
  &[data-searchopen="true"]{
    opacity: 1;
    visibility: visible;
    overflow-y:auto ;
  }
  @include mq(pc){
    top: 10dvh;
    left: 10vw;
    width: 80vw;
    height: 80dvh;
    padding:40px;
  }
  @include mq{
    @include sfs(15);
    padding-top:calc((35 / 390) * 100vw);
    padding-bottom:calc((30 / 390) * 100vw);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
  }
  &._open{
    overflow-y:auto ;
  }
  .sp-nav-btn{
    position: absolute;
    @include mq(pc){
      right: 30px;
      top:30px;
    }
    @include mq{
      right: calc((15 / 390) * 100vw);
      top:calc((20 / 390) * 100vw);
    }
  }
  a{
    text-decoration: none;
  }
  &-inner{
    @include mq(pc){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
    @include mq(spc){
      @include flx(column, flex-start, stretch, 30px);
    }
    @include mq{
      @include flx(column, flex-start, stretch, 20px);
    }
    >div{
      &:nth-of-type(2){
        @include mq(pc){
          position: relative;
          &:before{
            content: "";
            background: #000;
            width: 1px ;
            height: 100%;
          }
        }
      }
      &:nth-of-type(3){
        @include mq(pc){
          grid-column: 1 / 3;
        }
      }
      &:nth-of-type(4){
        @include mq(pc){
          grid-column: 1 / 3;
        }
      }
    }
  }
}