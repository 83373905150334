// Base
@import 'base/base';

// Mixin
@import 'mixin/mixin';
@import 'mixin/z-index';
@import 'mixin/animation';

// Plubin
@import 'plugin/slick';
@import 'plugin/swiper';



// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

//-----------
// layout
//-----------
@import 'layout/header';
@import 'layout/footer';

// ------------------------------
// 下層 共通
// ------------------------------
@import 'page/page';
@import 'page/parts';
@import 'page/top';
@import 'page/shop';
@import 'page/form';