.footsticky{
  position: sticky;
  bottom: 20px;
  z-index: 1;
  @include mq{
    bottom:calc((16 / 390) * 100vw);
  }
  .inner{
    display: flex;
    justify-content: right;
  }
}

.pagetop{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $white;
  box-shadow: 0 0 2px rgba(0,0,0,.35);
  cursor: pointer;
  @include mq{
    width: calc((36 / 360) * 100vw);
    height: calc((36 / 360) * 100vw);
  }
  &:before{
      @include icon('arrow_bl.svg', 8px, 14px)
      transform: rotate(-90deg);
  }
  &:hover{
    background: $color-bg01;
    transform: translateY(-3px);
  }
}
footer{
  background: $gray02;
  border-bottom:20px solid #9B9B9B;

  @include mq(pc){
    padding: 15px 0;
    margin-top: 60px;
  }
  @include mq{
    padding: 8px 0;
    margin-top: 30px;
    border-width: 10px;
  }
  >.inner{
    @include mq(pc){
      @include flx(row, space-between,center,0)
    }

  }
  .f-nav_sub{
    font-size: 1.4rem;
    @include flx(row, flex-start,center,15px)
    @include mq{
      display: none;
    }
    a{
      &:hover{
        text-decoration: none;
      }
    }
  }
}
.list-sns{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  li{
    @include mq(pc){
      width: 50px;
    }
    @include mq{
      width: calc((40 / 390) * 100vw);
    }
  }
}
.copyright{
  text-align: center;
  font-size: 1.2rem;
  color: $gray01;
  
  @include mq{
    @include sfs(11);
    padding:0 0 0;
  }
}